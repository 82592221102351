const CancellationPolicy = () => {
  return (
    <div className="pt-32 bg-[#121212] text-[#FFFFFF] p-2 w-full flex justify-center">
      <div className="w-5/6 flex flex-col gap-4">
        <div>
          <h1 className="text-4xl font-beautique font-bold underline">
            Cancellation Policy
          </h1>
        </div>
        <div>
          <div>
            <h2 className="text-3xl font-semibold leading-loose">
              Introduction
            </h2>
            <p className="text-xl tracking-wide">
              We are committed to providing you with the highest level of
              support and guidance as you embark on your entrepreneurial
              journey. To ensure fairness and clarity, please review our
              cancellation policy carefully.
            </p>
          </div>

          <div>
            <h3 className="text-2xl font-semibold pt-5">
              Cancellation by the Client
            </h3>
            <ul className=" flex flex-col gap-8 py-8">
              <li className="">
                <h2 className="text-xl font-bold">1. Initial Deposit : </h2>
                <p className="text-lg pt-2">
                  - A non-refundable deposit is required to secure your spot in
                  the program. This deposit covers administrative and onboarding
                  costs.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">
                  2. Cancellation Before Program Start :{" "}
                </h2>
                <ul className="pl-8 flex flex-col gap-2 pt-2 text-lg">
                  <li>
                    <p>
                      - If you cancel your enrollment more than 14 days before
                      the program start date, you will receive a full refund of
                      any payments made, minus the non-refundable deposit.
                    </p>
                  </li>
                  <li>
                    <p>
                      - Cancellations made within 14 days of the program start
                      date are not eligible for a refund, except in
                      extraordinary circumstances (to be determined at our
                      discretion)
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="text-xl font-bold">
                  3. Cancellation After Program Start: :{" "}
                </h2>
                <ul className="pl-8 flex flex-col gap-2 pt-2 text-lg">
                  <li>
                    <p>
                      - Once the program has started, cancellations are not
                      eligible for a refund.
                    </p>
                  </li>
                  <li>
                    <p>
                      - If you encounter extraordinary circumstances that
                      prevent you from continuing, please contact us immediately
                      to discuss potential options.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-2xl font-semibold">
              Cancellation by the Coach
            </h3>
            <ul className=" flex flex-col gap-8 py-8">
              <li className="">
                <h2 className="text-xl font-bold">
                  1. Program Rescheduling :{" "}
                </h2>
                <p className="text-lg pt-2">
                  - In the rare event that we need to reschedule or postpone the
                  program start date, you will be given the option to either
                  join the rescheduled program or receive a full refund,
                  including the deposit.
                </p>
              </li>
              <li className="">
                <h2 className="text-xl font-bold">
                  2. Termination of Services :{" "}
                </h2>
                <p className="text-lg pt-2">
                  - We reserve the right to terminate the coaching relationship
                  if there is a breach of agreement or if it is determined that
                  the program is not a good fit. In such cases, a prorated
                  refund for the remaining sessions will be provided.
                </p>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-2xl font-semibold leading-loose">
              Program Adjustments
            </h3>
            <ul className=" flex flex-col gap-8 py-8">
              <li className="">
                <h2 className="text-xl font-bold">
                  1. Program Modifications :{" "}
                </h2>
                <p className="text-lg pt-2">
                  - If you require modifications to the program due to
                  unforeseen circumstances, please discuss them with us as soon
                  as possible. We will work with you to find a suitable
                  solution.
                </p>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold leading-loose">
              Contact Information
            </h2>
            <p className="text-xl tracking-wide">
              For cancellations, rescheduling, or any other inquiries, please
              contact us at info@cellfawareness.com.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold leading-loose pt-5">
              Acceptance of Terms
            </h2>
            <p className="text-xl tracking-wide">
              By enrolling in the 18-week coaching program, you acknowledge that
              you have read, understood, and agree to abide by the terms
              outlined in this cancellation policy.
            </p>
          </div>

          <p className="text-xl tracking-wide">
            By using our website and participating in our programs, you consent
            to the terms of this Privacy Policy.{" "}
          </p>
          <p className="text-xl tracking-wide">
            Thank you for trusting us with your personal information.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CancellationPolicy;
