import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import CartCard from "../components/CartCard";
import { alertErrorMessage } from "../components/AlertMessage";
import Loader from "../components/Loader";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const Cart = ({ cartData, setCartData }) => {
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQty, setTotalQty] = useState(0);
  const [ProductData, setProductData] = useState([]);
  const [otherProducts, setOtherProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const loggedUserString = localStorage.getItem("user");
  const loggedUser = loggedUserString ? JSON.parse(loggedUserString) : null;

  //
  useEffect(() => {
    if (loggedUser) {
      const getCartData = async () => {
        try {
          const cartArray = [];
          const path = `cart-${loggedUser?.uid}`;
          const querySnapshot = await getDocs(collection(db, path));
          querySnapshot.forEach((doc) => {
            cartArray.push({ ...doc.data(), id: doc.id });
          });
          setCartData(cartArray);
        } catch (error) {
          console.error("Error fetching cart data:", error.message);
          alertErrorMessage(error.message);
        } finally {
          setLoading(false);
        }
      };
      getCartData();
    } else {
      setLoading(false);
    }
  }, [cartData]);

  useEffect(() => {
    let temp = 0;
    cartData.forEach((cartItem) => {
      const price = parseInt(cartItem?.product?.price) || 0;
      const quantity = cartItem?.quantity || 0;
      temp += price * quantity;
    });
    setTotalAmount(temp);
  }, [cartData]);

  useEffect(() => {
    let temp = 0;
    cartData.forEach((cartItem) => {
      temp += cartItem?.quantity || 0;
    });
    setTotalQty(temp);
  }, [cartData]);

  //   Products

  useEffect(() => {
    const getProducts = () => {
      const productsArray = [];
      const path = "products";

      getDocs(collection(db, path))
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            productsArray.push({ ...doc.data(), id: doc.id });
          });
          setProductData(productsArray);
        })
        .catch((error) => console.log(error.message));
    };
    getProducts();
  }, []);

  useEffect(() => {
    if (ProductData.length > 0 && cartData.length > 0) {
      const cartProductIds = cartData.map((item) => item.product.name);
      const filteredProducts = ProductData.filter(
        (product) => !cartProductIds.includes(product.name)
      );
      setOtherProducts(filteredProducts);
    } else {
      setOtherProducts(ProductData);
    }
  }, [ProductData, cartData]);

  // paypal

  const initialOptions = {
    clientId:
      "Af8cmRn8_x5kTvqQIbnavwDWovyyRK4GUZR_-InmjIJ1x6MzIVukmpza8BDECT7rQU5ohMTAPGeGLD_J",
    currency: "USD",
    intent: "capture",
  };

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)] backdrop-blur-sm flex justify-center z-[999] items-center">
          <div className="flex justify-center items-center">
            <Loader loading={loading} color="#FFFFFF33" />
          </div>
        </div>
      )}
      <div className="pt-24 w-full bg-[#121212] flex flex-col items-center">
        <div className="w-11/12">
          <div className="py-6 px-8 w-full text-[#FFFFFF] font-beautique font-bold text-[24px] sm:text-[32px] leading-6 text-center">
            {cartData.length > 0 && Cart}
          </div>

          {cartData.length > 0 ? (
            <div className="w-full px-4 sm:px-8 pt-12 pb-24 flex flex-col gap-8 md:gap-16">
              <div className="w-full flex flex-col xl:flex-row gap-8 xl:gap-16 items-center">
                <div className="w-full lg:w-[939px] flex flex-col gap-[10px]">
                  <div className="w-full h-auto px-[10px] pb-6 gap-[10px] flex flex-col border-t border-b border-[#FFFFFF]">
                    {cartData?.map((item) => (
                      <CartCard
                        key={item?.id}
                        itemData={item}
                        userId={loggedUser?.uid}
                      />
                    ))}
                  </div>

                  <div className="w-full h-6 flex justify-between text-[#FFFFFF]">
                    <div className="w-1/2 pr-8 pl-[20%] sm:pl-[145px] font-semibold">
                      {totalQty} Items
                    </div>
                    <div className="w-1/2 font-semibold flex">
                      <span>Subtotal :</span>
                      <span>${totalAmount}</span>
                    </div>
                  </div>
                </div>

                <div className="w-full lg:w-[930px] xl:w-[390px] p-6 gap-6 flex flex-col bg-[#FFFFFF]">
                  <div className="w-full h-auto flex flex-col gap-2">
                    <div className="font-medium">Enter Promo code</div>
                    <div className="h-10 flex">
                      <input
                        className="w-3/5 py-2 px-3 border border-[#1212121A] mr-1"
                        placeholder="Enter the code..."
                      />
                      <button className="w-2/5 bg-[#121212] text-[#FFFFFF] py-2">
                        Submit
                      </button>
                    </div>
                  </div>

                  <div className="w-full h-auto gap-2 flex flex-col font-medium">
                    <div className="flex justify-between">
                      <span>Item cost</span>
                      <span className="text-sm">${totalAmount}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Delivery</span>
                      <span className="text-sm">FREE</span>
                    </div>
                    {/* <div className="flex justify-between">
                        <span>Discount</span>
                        <span className="text-sm">{discount}%</span>
                    </div>
                    <div className="flex justify-between">
                        <span>Tax</span>
                        <span className="text-sm">${tax}</span>
                    </div> */}
                  </div>

                  <div className="w-full h-6 flex justify-between font-medium">
                    <span>Total</span>
                    <span className="text-sm">${totalAmount}</span>
                  </div>

                  {/* <button className="w-full h-10 py-2 font-medium text-sm bg-[#121212] text-[#FFFFFF]">Checkout</button> */}
                  <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                      style={{ layout: "horizontal" }}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: totalAmount.toString(),
                                currency_code: "USD", // Replace with your actual amount
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          console.log("Payment completed", details);
                          // Handle post-payment logic here
                        });
                      }}
                    />
                  </PayPalScriptProvider>
                </div>
              </div>

              {/* <div className="w-full flex flex-col gap-8 lg:gap-14 text-[#FFFFFF]">
            <div className="text-[24px] sm:text-[32px] leading-8 sm:leading-10 font-medium font-beautique">
                Other Products
            </div>
            <div className="w-full flex flex-wrap gap-6 lg:gap-10 justify-center">
                {otherProducts.map(item =>(
                    <div className="w-[45%] sm:w-[30%] md:w-[189px] h-auto flex flex-col gap-4">
                    <img className="h-[189px] w-full object-cover" src={item?.image} alt="product_img" />
                    <div className="w-full h-auto flex flex-col gap-4">
                        <div className="font-medium font-beautique">{item?.name}</div>
                        <div className="font-medium">Price-${item?.price}</div>
                    </div>
                </div>
                ))}
                <button className="w-full sm:w-auto h-10 md:h-[63px] border border-[#FFFFFF99] p-2 font-medium text-sm cursor-pointer"onClick={()=>Navigate("/shop")}>
                    More
                </button>
            </div>
        </div> */}

              <div className="w-full max-w-[1448px] mx-auto flex flex-col gap-10 text-[#FFFFFF]">
                <h1 className="text-[32px] leading-[40px] font-medium pl-6 tracking-wide">
                  Other Products
                </h1>
                <div className="w-full max-w-[1448px] flex flex-wrap justify-center gap-6">
                  {otherProducts.map((p) => (
                    <Link
                      key={p.id}
                      to={`/productDetail/${p.id}`}
                      className="w-full max-w-[378px] flex flex-col gap-[22px]"
                    >
                      <img
                        className="h-[378px] w-full bg-[#FFFFFF]"
                        src={p.image}
                        alt="product_img"
                      />
                      <div className="flex flex-col gap-4">
                        <div className="font-beautique font-medium">
                          {p.name}
                        </div>
                        <div className="font-medium">Price-${p.price}</div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center flex-wrap">
              <img
                src="/img/empty-cart.svg"
                alt="empty_cart"
                className="max-h-[500px]"
              />
              <div className=" font-bold text-[24px] sm:text-[32px] text-[#FFFFFF] flex flex-col gap-2 items-center">
                <h2 className="text-2xl">Your CELLF cart is Empty</h2>
                <h3
                  className="text-xl underline cursor-pointer"
                  onClick={() => Navigate("/shop")}
                >
                  SHOP
                </h3>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
