const AboutUs = () => {
  return (
    <div className="pt-32 bg-[#121212] text-[#FFFFFF] p-2 w-full flex justify-center">
      <div className="w-5/6 flex flex-col gap-4">
        <div>
          <h1 className="text-4xl font-beautique font-bold leading-loose underline">
            About Us
          </h1>
        </div>
        <h2 className="text-3xl font-semibold">
          Company Bio: The CELLF Awareness Project, LLC
        </h2>
        <div>
          <p className="text-xl tracking-wide">
            At The CELLF Awareness Project, LLC, our mission is to elevate
            humanity by empowering individuals to embrace their true selves.
            Founded by CEO Melissa LaPlante, who is also an accomplished
            Self-Empowerment Coach, Author, Podcaster, and Keynote Speaker, our
            team is passionate about serving the Neurodivergent and
            Neuro-Questioning communities.
          </p>
          <br />
          <p className="text-xl tracking-wide">
            Melissa brings over a decade of coaching experience to her role. The
            first half of that decade her career was dedicated to performance
            development coaching within the IT industry, where she honed her
            skills in helping professionals reach their full potential. For the
            subsequent years, she focused on supporting Neurodivergent foster
            teens, providing them with the tools and guidance they need to
            thrive.
          </p>
          <br />

          <p className="text-xl tracking-wide">
            We believe that each person is a vital cell in the larger organism
            of humanity, possessing unique gifts that can make a meaningful
            impact. Through our initiatives, we help individuals discover their
            "cellular purpose," guiding them toward fulfilling, purpose-driven
            lives and careers.
          </p>
          <br />

          <p className="text-xl tracking-wide">
            Our vision extends beyond individual empowerment; we aim to
            cultivate a network of purpose-driven professionals and
            entrepreneurs through our comprehensive business development
            programs. By uniting these changemakers, we strive to launch
            collaborative global projects that address pressing needs and
            enhance the well-being of communities worldwide.
          </p>
          <br />

          <p className="text-xl tracking-wide">
            Melissa is committed to nurturing every individual she encounters,
            helping them unlock their potential and contribute their gifts to
            the world. She understands that she may not get to sit under the
            shade of the trees she is planting, but she remains dedicated to
            planting seeds for a brighter future where everyone’s needs are met
            through shared purpose and collective action. Join us in our mission
            to create a more empowered and compassionate world.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
