const FearlessnessFrequency = () => {
  return (
    <div className="pt-32 bg-[#121212] text-[#FFFFFF] p-2 w-full flex justify-center">
      <div className="w-5/6 flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <h1 className="text-4xl font-beautique font-bold">
            Fearlessness Frequency Blueprint
          </h1>
          <p className="text-xl">
            Unlock your true self and embrace your unique purpose with
            confidence and clarity.
          </p>
          {/* <p className="text-xl leading-loose font-bold">$5000</p> */}
        </div>
        <div>
          <h2 className="text-3xl font-semibold leading-loose">
            Service Description
          </h2>
          <p className="text-xl tracking-wide">
            Embark on a transformative journey with the Fearlessness Frequency
            Blueprint, a 5 month coaching program tailored specifically for
            Neurodivergent individuals ready to break free from the grips of
            burnout and reclaim their lives. This empowering package recognizes
            your resilience and creativity, providing a supportive environment
            where you can explore your authentic self and harness your unique
            gifts.
          </p>
          {/* <h3 className="text-2xl font-medium leading-loose">
            Throughout our adventure, we’ll explore:
          </h3>
          <ul className=" flex flex-col gap-8 py-8">
            <li className="">
              <h2 className="text-xl font-bold">
                Week 1: Resilience and Self-Discovery{" "}
              </h2>
              <p className="text-lg pt-1">
                Uncover your inner strength and see sensitivity as your
                superpower. Together, we’ll navigate personal challenges,
                turning setbacks into stepping stones for growth.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                Week 2: Unleashing Creativity and Authenticity
              </h2>
              <p className="text-lg pt-1">
                Reconnect with your creative spirit and embrace a life that
                aligns with your core values. Let’s celebrate your individuality
                and unlock your full potential.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                Week 3: Values Alignment and Connection
              </h2>
              <p className="text-lg pt-1">
                Clarify your fundamental values to build a meaningful
                foundation. We’ll cultivate relationships that inspire and
                uplift you, ensuring your journey is supported.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                Week 4: Overcoming Challenges and Fear{" "}
              </h2>
              <p className="text-lg pt-1">
                The coaching program fee is non-transferable to another
                individual or program.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                Week 5: Empowerment and Self-Actualization
              </h2>
              <p className="text-lg pt-1">
                Reclaim your power and step boldly into your narrative. Let’s
                celebrate your growth as you move towards a life filled with
                purpose and fulfillment.
              </p>
            </li>
          </ul> */}
          <p className="text-xl tracking-wide">
            Through personalized coaching, you’ll gain the tools to navigate
            your internal conflicts and conquer the fears holding you back from
            meaningful change. Together, we'll build a roadmap that aligns your
            career and life with your core values, fostering a sense of
            connection, empowerment, and autonomy.
          </p>
          <p className="text-xl tracking-wide">
            You’re not just escaping burnout; you’re stepping into a
            purpose-driven existence where your talents are celebrated, and your
            emotional needs are met. Join a community of like-minded individuals
            who understand your journey, and let’s create a future that honors
            your true potential and aspirations. Your path to fulfillment and
            freedom starts here.
          </p>
        </div>
        {/* <div>
          <h2 className="text-3xl font-semibold leading-loose">
            Cancellation Policy
          </h2>
          <p className="text-xl tracking-wide">
            Thank you for your interest in our coaching program! Please read the
            following policy before making a purchase:
          </p>
          <div>
            <ul className=" flex flex-col gap-8 py-8">
              <li className="">
                <h2 className="text-xl font-bold">1. Payment : </h2>
                <p className="text-lg pt-1">
                  The total fee for the coaching program is $5000, payable in
                  full at the time of purchase.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">2. Refund Policy : </h2>
                <p className="text-lg pt-1">
                  Refunds will only be issued within 3 days of the date of
                  purchase. After this period, the coaching program fee is
                  nonrefundable.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">3. Cancellation : </h2>
                <p className="text-lg pt-1">
                  If you wish to cancel your participation in the coaching
                  program within the 3-day refund period, please contact us at
                  info@cellfawareness.com to request a refund.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">4. Transferability : </h2>
                <p className="text-lg pt-1">
                  The coaching program fee is non-transferable to another
                  individual or program.
                </p>
              </li>
              <li className="flex flex-col gap-2">
                <h2 className="text-xl font-bold">5. Program Details : </h2>
                <p className="text-lg">
                  <strong>Duration :</strong> 18 weeks consisting of 36 Coaching
                  sessions (18 weekly Group Sessions and 18 weekly one-on-one
                  sessions)
                </p>
                <h3 className="text-lg font-bold">Modules</h3>
                <ul className="pl-8 flex flex-col gap-2 text-lg">
                  <li>
                    <p>
                      <strong>Module 1:</strong> Discovering Your CELLF (Weeks
                      1-4)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Module 2:</strong> Building Your Business
                      Foundation (Weeks 5-8)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Module 3:</strong> Developing Your Product/Service
                      (Weeks 9-12)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Module 4:</strong> Launching and Growing Your
                      Business (Weeks 13-18)
                    </p>
                  </li>
                </ul>
                <h3 className="text-lg font-bold">Features</h3>
                <ul className="pl-8 flex flex-col gap-2 text-lg">
                  <li>Self-discovery exercises</li>
                  <li>Strategic planning sessions</li>
                  <li>Product development guidance</li>
                  <li>Launch and growth strategies</li>
                  <li>
                    Emphasis on authenticity, purpose, continuous learning, and
                    community building.
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="text-xl font-bold">6. Contact Information: </h2>
                <p className="text-lg pt-1">
                  If you have any questions or concerns about the coaching
                  program or this policy, please contact us at
                  info@cellfawareness.com
                </p>
              </li>
            </ul>
          </div>
          <p className="text-xl tracking-wide">
            By purchasing our coaching program, you acknowledge that you have
            read, understood, and agreed to the above policy.
          </p>
          <p className="text-xl tracking-wide">
            Thank you for choosing our coaching program. We look forward to
            supporting you on your journey to Fearlessly Authentic
            Entrepreneurship.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default FearlessnessFrequency;
