const PrivacyPolicy = () => {
  return (
    <div className="pt-32 bg-[#121212] text-[#FFFFFF] p-2 w-full flex justify-center">
      <div className="w-5/6 flex flex-col gap-4">
        <div>
          <h1 className="text-4xl font-beautique font-bold leading-loose underline">
            Privacy Policy
          </h1>
        </div>
        <div>
          <h2 className="text-3xl font-semibold">Effective Date: 1/01/2024</h2>
        </div>
        <div>
          <div>
            <ul className=" flex flex-col gap-8 py-8">
              <li className="">
                <h2 className="text-xl font-bold">1. Introduction : </h2>
                <p className="text-lg pt-2">
                  We value your privacy and are committed to protecting your
                  personal information. This Privacy Policy outlines how we
                  collect, use, disclose, and protect your information when you
                  visit our website and participate in our coaching programs.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">
                  2. Information We Collect :{" "}
                </h2>
                <ul className="pl-8 flex flex-col gap-2 pt-2 text-lg list-disc">
                  <li>
                    <p>
                      <strong>Personal Information:</strong> When you register
                      for our programs, subscribe to our newsletter, or contact
                      us, we may collect personal information such as your name,
                      email address, phone number, and billing information.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Usage Data:</strong> We collect information about
                      your interactions with our website, such as IP address,
                      browser type, pages visited, and the date and time of your
                      visit.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Cookies and Tracking Technologies:</strong> We use
                      cookies and similar technologies to enhance your
                      experience on our website, understand usage patterns, and
                      improve our services.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="text-xl font-bold">
                  3. How We Use Your Information :{" "}
                </h2>
                <ul className="pl-8 flex flex-col gap-2 pt-2 text-lg list-disc">
                  <li>
                    <p>
                      <strong>To Provide Services:</strong> We use your personal
                      information to deliver our coaching programs, process
                      payments, and provide customer support.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Communication:</strong> We may use your contact
                      information to send you updates, newsletters, and
                      promotional materials related to our services. You can
                      opt-out of these communications at any time.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Improvement and Personalization:</strong> We
                      analyze usage data to improve our website, customize your
                      experience, and develop new services.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Legal Compliance:</strong> We may use your
                      information to comply with legal obligations, resolve
                      disputes, and enforce our agreements.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="text-xl font-bold">
                  4. How We Share Your Information :{" "}
                </h2>
                <ul className="pl-8 flex flex-col gap-2 pt-2 text-lg list-disc">
                  <li>
                    <p>
                      <strong>Service Providers:</strong> We may share your
                      information with third-party service providers who assist
                      us in operating our website and delivering our services.
                      These providers are obligated to protect your information
                      and use it only for the purposes specified by us.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Legal Requirements:</strong> We may disclose your
                      information if required by law, in response to legal
                      processes, or to protect our rights, property, and safety.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Business Transfers:</strong> In the event of a
                      merger, acquisition, or sale of assets, your information
                      may be transferred to the new owner.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="text-xl font-bold">5. Data Security : </h2>
                <p className="text-lg pt-2">
                  We implement appropriate technical and organizational measures
                  to protect your personal information against unauthorized
                  access, loss, or misuse. However, no method of transmission
                  over the internet or electronic storage is 100% secure, and we
                  cannot guarantee absolute security.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">6. Your Rights : </h2>
                <ul className="pl-8 flex flex-col gap-2 pt-2 text-lg list-disc">
                  <li>
                    <p>
                      <strong>Access and Correction:</strong> You have the right
                      to access and correct your personal information held by
                      us. You can update your information by contacting us.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Opt-Out:</strong> You can opt-out of receiving
                      promotional communications from us by following the
                      unsubscribe instructions in our emails or contacting us
                      directly.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Data Deletion:</strong> You can request the
                      deletion of your personal information by contacting us.
                      Please note that certain information may be retained for
                      legal or administrative purposes.
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="text-xl font-bold">7. Third-Party Links: </h2>
                <p className="text-lg pt-2">
                  Our website may contain links to third-party websites. We are
                  not responsible for the privacy practices or content of these
                  sites. We encourage you to review the privacy policies of any
                  third-party sites you visit.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">8. Children's Privacy: </h2>
                <p className="text-lg pt-2">
                  Our services are not intended for individuals under the age of
                  18. We do not knowingly collect personal information from
                  children. If we become aware that we have inadvertently
                  collected personal information from a child, we will take
                  steps to delete it as soon as possible.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">
                  9. Changes to This Privacy Policy:{" "}
                </h2>
                <p className="text-lg pt-2">
                  We may update this Privacy Policy from time to time. Any
                  changes will be posted on this page with an updated effective
                  date. We encourage you to review this policy periodically to
                  stay informed about how we are protecting your information.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">10. Contact Us: </h2>
                <p className="text-lg pt-2">
                  If you have any questions or concerns about this Privacy
                  Policy or our data practices, please contact us at:
                  info@cellfawareness.com{" "}
                </p>
              </li>
            </ul>
          </div>
          <p className="text-xl tracking-wide">
            By using our website and participating in our programs, you consent
            to the terms of this Privacy Policy.{" "}
          </p>
          <p className="text-xl tracking-wide">
            Thank you for trusting us with your personal information.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
