import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { db } from "../firebase";
import { RiDeleteBinLine } from "react-icons/ri";
import { alertSuccessMessage } from "./AlertMessage";

const CartCard = (props) => {
  const [quantity, setQuantity] = useState(props?.itemData.quantity);

  const handleIncrease = async () => {
    setQuantity((prevQuantity) => prevQuantity + 1);

    const itemref = doc(db, `cart-${props.userId}`, `${props.itemData?.id}`);
    await updateDoc(itemref, {
      quantity: quantity + 1,
    }).then(() => console.log("Quantity updated"));
  };

  const handleDecrease = async () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);

      const itemref = doc(db, `cart-${props.userId}`, `${props.itemData?.id}`);
      await updateDoc(itemref, {
        quantity: quantity - 1,
      }).then(() => console.log("Quantity updated"));
    }
  };

  const deleteCartItem = async () => {
    try {
      const cartDocRef = doc(db, `cart-${props?.userId}`, props.itemData?.id);
      await deleteDoc(cartDocRef);
      alertSuccessMessage("Item deleted successfully");
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <>
      <div className="w-full flex flex-col md:flex-row px-6 pt-6 gap-8 md:gap-20 text-[#FFFFFF]">
        <div className="flex gap-4 h-24 w-full md:w-[336px]">
          <img
            className="w-24 h-full"
            src={props?.itemData?.product?.image}
            alt="product_img"
          />
          <div className="flex flex-col h-full w-56">
            <div className="font-semibold">
              {props?.itemData?.product?.name}
            </div>
            <div className="font-medium text-sm">Color-Black</div>
            {/* <div className="font-medium text-sm">In Stock</div> */}
          </div>
        </div>
        <div className="flex w-full justify-between md:w-auto">
          <div className="flex flex-col w-20 text-center gap-3">
            <div className="font-semibold text-xl">Price</div>
            <div className="font-medium">
              ${props?.itemData?.product?.price}
            </div>
          </div>
          <div className="flex flex-col items-center w-20 gap-4">
            <div className="font-semibold text-xl">Qty</div>
            <div className="w-14 h-5 flex justify-center items-center border border-gray-400 rounded-md">
              <span
                className="w-7 p-2 cursor-pointer flex justify-center items-center"
                onClick={handleDecrease}
              >
                <IoMdRemove />
              </span>
              <span className="w-3 leading-[56px] font-semibold">
                {quantity}
              </span>
              <span
                className="w-7 p-2 cursor-pointer flex justify-center items-center"
                onClick={handleIncrease}
              >
                <IoMdAdd />
              </span>
            </div>
          </div>
          <div className="flex flex-col w-20 text-center gap-3">
            <div className="font-semibold text-xl">Total</div>
            <div className="font-medium">
              ${props?.itemData?.product?.price * quantity}
            </div>
          </div>
          <div className="flex items-center">
            <RiDeleteBinLine
              className="w-9 h-9 cursor-pointer"
              onClick={deleteCartItem}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CartCard;
