const YourselfIntensive = () => {
  return (
    <div className="pt-32 bg-[#121212] text-[#FFFFFF] p-2 w-full flex justify-center">
      <div className="w-5/6 flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <h1 className="text-4xl font-beautique font-bold">
            UNF*CK YOURSELF INTENSIVE
          </h1>
        </div>
        <div>
          <h2 className="text-3xl font-semibold leading-loose">
            Package Description:
          </h2>
          <p className="text-xl tracking-wide">
            Are you a neurodivergent professional feeling overwhelmed and
            trapped in the cycle of burnout? The "UNF*CK YOURSELF INTENSIVE" is
            a dynamic 90-minute one-time coaching session designed specifically
            for you.
            <br />
            In this transformative session, we will dive deep into your current
            challenges and identify the root causes of your professional
            stagnation. Together, we will explore the unique barriers that may
            be holding you back, whether they stem from internal beliefs,
            external pressures, or a misalignment with your true self.
          </p>
          <h3 className="text-2xl font-medium leading-loose">
            What to Expect:
          </h3>
          <ul className=" flex flex-col gap-8 py-8">
            <li className="">
              <h2 className="text-xl font-bold">
                1: Clarifying Your Stuck Points:
              </h2>
              <p className="text-lg pt-1">
                We’ll start by pinpointing exactly where you feel stuck in your
                career or life. This could involve examining your work habits,
                emotional responses, or even your environment.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">2: Understanding the ‘Why’:</h2>
              <p className="text-lg pt-1">
                Once we identify your stuck points, we’ll delve into the
                underlying reasons behind them. By gaining clarity on these
                obstacles, you will be empowered to see the bigger picture and
                understand how your neurodivergent traits may play a role.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">3: Actionable Steps:</h2>
              <p className="text-lg pt-1">
                The session will culminate in a personalized action plan. We
                will outline the first actionable steps you can take to start
                moving forward, along with a specific goal to strive for. This
                plan will be tailored to your unique needs and strengths,
                ensuring that it resonates with who you are.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">4: Empowerment Tools:</h2>
              <p className="text-lg pt-1">
                You’ll leave the session equipped with practical tools and
                strategies to help maintain momentum and foster resilience
                against burnout in the future.
              </p>
            </li>
          </ul>
          <p className="text-xl tracking-wide">
            This intensive session is your opportunity to reclaim your path and
            ignite your potential. Let’s work together to unf*ck your situation
            and pave the way to a more fulfilling professional life.
          </p>
          <p className="text-xl tracking-wide">
            <strong>Ready to get started?</strong>{" "}
            <a
              href="https://calendly.com/cellfawarenessproject/fearlessnessfrequency"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              BOOK YOUR SESSION
            </a>{" "}
            today and take the first step towards empowerment
            and transformation!
          </p>
        </div>
      </div>
    </div>
  );
};

export default YourselfIntensive;
