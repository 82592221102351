import { Route, Routes } from "react-router-dom";
import Home from "../screens/Home";
import Blog from "../screens/Blog";
import Masterclass from "../screens/Materclass";
import Shop from "../screens/Shop";
import BlogDetail from "../screens/BlogDetail";
import ProductDetail from "../screens/ProductDetail";
import Cart from "../screens/Cart";
import LoginModal from "../components/LoginModal";
import SignupModal from "../components/SignupModal";
import DiscoveryCall from "../screens/DiscoveryCall";
import FearlessnessFrequency from "../screens/FearlessnessFrequency";
import { ToastContainer } from "react-toastify";
import AboutUs from "../screens/AboutUs";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import CancellationPolicy from "../screens/CancellationPolicy";
import FAQ from "../screens/FAQ";
import BusinessBlueprint from "../screens/BusinessBlueprint";
import YourselfIntensive from "../screens/YourselfIntensive";
import AudioPodcast from "../screens/AudioPodcast";
import VideoPodcast from "../screens/VideoPodcast";

const Router = ({ cartData, setCartData, loggedUser }) => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        limit={2}
        theme="light"
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/discoveryCall" element={<DiscoveryCall />} />
        <Route path="/fearlessFrequency" element={<FearlessnessFrequency />} />
        <Route path="/yourselfIntensive" element={<YourselfIntensive />} />
        <Route path="/businessBlueprint" element={<BusinessBlueprint />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/cancellationPolicy" element={<CancellationPolicy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/blog" element={<Blog loggedUser={loggedUser} />} />
        <Route path="/blogDetail/:id" element={<BlogDetail />} />
        <Route path="/masterClass" element={<Masterclass />} />
        <Route path="/shop" element={<Shop loggedUser={loggedUser} />} />
        <Route
          path="/productDetail/:id"
          element={
            <ProductDetail cartData={cartData} setCartData={setCartData} />
          }
        />
        <Route
          path="/cart"
          element={<Cart cartData={cartData} setCartData={setCartData} />}
        />
        <Route path="/login" element={<LoginModal />} />
        <Route path="/signup" element={<SignupModal />} />
        <Route
          path="/podcasts/video"
          element={<VideoPodcast loggedUser={loggedUser} />}
        />
        <Route
          path="/podcasts/audio"
          element={<AudioPodcast loggedUser={loggedUser} />}
        />
      </Routes>
    </>
  );
};

export default Router;
