const FAQ = () => {
  return (
    <div className="pt-32 bg-[#121212] text-[#FFFFFF] p-2 w-full flex justify-center">
      <div className="w-5/6 flex flex-col gap-4">
        <div>
          <h1 className="text-4xl font-beautique font-bold leading-loose underline">
            Frequently Asked Questions(FAQ)
          </h1>
        </div>

        <div>
          <ul className=" flex flex-col gap-8 py-8">
            <li className="">
              <h2 className="text-xl font-bold">
                1. What is "Unlocking Your Fearlessness Frequency"?
              </h2>
              <p className="text-lg pt-2">
                "Unlocking Your Fearlessness Frequency" is a 5-month (18-week)
                group coaching program designed for purpose-driven, aspiring
                entrepreneurs. The program helps you transform your passion into
                a profitable business that makes a positive impact on the world.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">2. Who is this program for?</h2>
              <p className="text-lg pt-2">
                This program is ideal for ambitious individuals who believe in
                making a positive impact, value personal fulfillment and
                passion, and desire autonomy and self-expression. It's perfect
                for those who are passionate about specific causes, interested
                in personal development, and looking to transition from a stable
                yet unfulfilling career to entrepreneurship.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                3. What will I learn in this program?
              </h2>
              <h3 className="text-lg font-semibold pt-2">You will learn to:</h3>
              <ul className="pl-8 flex flex-col gap-2 pt-2 text-lg list-disc">
                <li>
                  <p>
                    Excel at being yourself and leveraging your unique
                    strengths.
                  </p>
                </li>
                <li>
                  <p>Turn your greatest passion into a profitable business.</p>
                </li>
                <li>
                  <p>
                    Develop business, marketing, and AI strategies to propel
                    your business forward.
                  </p>
                </li>
                <li>
                  <p>Overcome fears, self-doubt, and limiting beliefs.</p>
                </li>
                <li>
                  <p>Build and launch a purpose-driven business.</p>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                4. How is the program structured?
              </h2>
              <h3 className="text-lg font-semibold pt-2">
                The program spans 5 months and includes:
              </h3>
              <ul className="pl-8 flex flex-col gap-2 pt-2 text-lg list-disc">
                <li>
                  <p>Weekly group coaching calls.</p>
                </li>
                <li>
                  <p>Self-discovery and alignment workshops.</p>
                </li>
                <li>
                  <p>Business planning, branding, and marketing strategies.</p>
                </li>
                <li>
                  <p>AI integration and automation techniques.</p>
                </li>
                <li>
                  <p>Implementation, launch, and growth strategies.</p>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                5. What is the ideal class size?
              </h2>
              <p className="text-lg pt-2">
                The ideal class size is between 10 to 20 participants. This
                ensures a supportive and engaging environment where each
                participant receives personalized attention and can actively
                contribute to the group.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                6. What kind of support will I receive?
              </h2>
              <h3 className="text-lg font-semibold pt-2">
                Participants will receive:
              </h3>
              <ul className="pl-8 flex flex-col gap-2 pt-2 text-lg list-disc">
                <li>
                  <p>Weekly group coaching sessions.</p>
                </li>
                <li>
                  <p>
                    Access to a resource library with templates, worksheets, and
                    guides.
                  </p>
                </li>
                <li>
                  <p>An accountability partner system.</p>
                </li>
                <li>
                  <p>
                    Membership in a private community for ongoing support and
                    networking.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                7. How much time will I need to commit each week?
              </h2>
              <p className="text-lg pt-2">
                Participants should expect to commit approximately 5-7 hours per
                week. This includes attending coaching calls, completing
                assignments, and engaging with the community.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                8. What if I miss a session?
              </h2>
              <p className="text-lg pt-2">
                All sessions are recorded and will be available for replay. You
                can catch up on any missed content at your convenience.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                9. How do I know if this program is right for me?
              </h2>
              <p className="text-lg pt-2">
                If you have an aching dream to help the world with your talent,
                feel unfulfilled in your current career, and are motivated by
                the potential for positive change and personal achievement, this
                program is designed for you.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                10. How do I enroll in the program?
              </h2>
              <p className="text-lg pt-2">
                To enroll, please visit our enrollment page and follow the
                registration instructions. Spaces are limited to ensure a
                quality experience for all participants.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                11. What is the cost of the program?
              </h2>
              <p className="text-lg pt-2">
                Please book a CELLF Discovery call for detailed information on
                the program cost and available payment plans.
              </p>
            </li>
            <li>
              <h2 className="text-xl font-bold">
                12. Can I speak with someone before enrolling?
              </h2>
              <p className="text-lg pt-2">
                Absolutely! If you have any questions or need more information,
                please contact us at info@cellfawareness.com or schedule a free
                CELLF Discovery call.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
