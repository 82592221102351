import { useRef, useState } from "react";
import emailjs from "emailjs-com";
import { alertSuccessMessage } from "../components/AlertMessage";

const Masterclass = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  function scrollToForm() {
    const formElement = document.getElementById("bottomForm");
    formElement.scrollIntoView({ behavior: "smooth" });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendWelcomeEmail(formData.firstName, formData.lastName, formData.email);
    sendEmailToAdmin(
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.message
    );
    setFormData({ firstName: "", lastName: "", email: "", message: "" }); // Reset form

    // emailjs
    //   .send(
    //     "service_8e5ad8a",
    //     "template_uu83i9d",
    //     {
    //       from_name: formData.firstName + " " + formData.lastName,
    //       from_email: formData.email,
    //       reply_to: formData.email,
    //       message: formData.message,
    //     },
    //     "QCUb7Xzd84monUsqO"
    //   )
    //   .then(
    //     (response) => {
    //       alert("Email sent successfully!", response.status, response.text);
    //       setFormData({ firstName: "", lastName: "", email: "", message: "" }); // Reset form
    //     },
    //     (error) => {
    //       alert("Failed to send email.", error);
    //     }
    //   );
  };

  const sendWelcomeEmail = (firstName, lastName, email) => {
    // Define template parameters (dynamic data passed to the email template)
    const templateParams = {
      user_name: firstName, // This should match the variable name in your EmailJS template
      user_email: email, // This should match the variable name in your EmailJS template
    };

    // Send the email using the EmailJS send method
    emailjs
      .send(
        "service_jmsnt7o", // Your Service ID
        "template_kak7uiq", // Your Template ID
        templateParams, // Dynamic template parameters (name, email, etc.)
        "NNBVqBuoUscbk00MR" // Your Public Key (User ID)
      )
      .then(
        (result) => {
          alertSuccessMessage("Email sent successfully!", result.text);
          showDownloadAlert();
        },
        (error) => {
          console.error("Failed to send email", error.text);
        }
      );
  };

  function showDownloadAlert() {
    // Create an alert with a clickable download link
    const userResponse = window.confirm(
      "Thank you for signing up to Masterclass! Click OK to download your Masterclass Companion Workbook."
    );

    // If user clicks OK, initiate the PDF download
    if (userResponse) {
      // Replace 'your-pdf-url.pdf' with the actual URL of your PDF
      const pdfUrl = "Masterclass Companion Workbook.pdf";
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = "Masterclass Companion Workbook.pdf"; // This sets the default download name
      link.click();
    }
  }

  const sendEmailToAdmin = (firstName, lastName, email, message) => {
    // Define template parameters (dynamic data passed to the email template)
    const templateParams = {
      type: "Masterclass Registration",
      user_name: firstName + " " + lastName, // This should match the variable name in your EmailJS template
      user_email: email,
      user_message: message,
    };

    // Send the email using the EmailJS send method
    emailjs
      .send(
        "service_jmsnt7o", // Your Service ID
        "template_4rjp3pb", // Your Template ID
        templateParams, // Dynamic template parameters (name, email, etc.)
        "NNBVqBuoUscbk00MR" // Your Public Key (User ID)
      )
      .then(
        (result) => {
          console.log("Email sent successfully!", result.text);
        },
        (error) => {
          console.error("Failed to send email", error.text);
        }
      );
  };

  return (
    <>
      <div className="relative w-full h-auto">
        <img
          src="/img/signature_002.JPG"
          alt="master_class_bg"
          className="w-full object-cover min-h-[220px] xs:min-h-[420px] md:min-h-screen max-h-screen"
        />

        <div className="absolute top-[30%] sm:top-[20%] left-4 md:left-12 w-[90%] max-w-screen-lg px-4 md:px-0">
          <div className="font-beautique font-medium text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl leading-tight text-[#0BD0C0] text-center md:text-left">
            90 minutes master class
          </div>
          <div className="text-xs sm:text-sm md:text-lg lg:text-xl font-medium text-center md:text-left mt-2 md:mt-0 block sm:hidden">
            Mastering the Art of Manifestation in 12 Simple Steps
          </div>
        </div>

        {/* baad me jb date fix ho jayegi tb uncomment kr dena hai */}
        {/* <div className="absolute top-[58%] xs:top-[60%] sm:top-[58%] md:top-[50%] left-4 md:left-12 w-[90%] max-w-screen-md flex flex-col items-center md:items-start gap-1 sm:gap-2  text-left">
          <div className="font-bold text-xs sm:text-lg md:text-2xl lg:text-3xl leading-6 sm:leading-7">
            August 1 Thursday
          </div>
          <div className="text-xs invisible sm:visible sm:text-sm md:text-lg lg:text-xl leading-6">
            Only 20 spots are available
          </div>
        </div> */}

        <img
          src="img/masterclass.jpeg"
          alt=""
          className="absolute top-[30%] xs:top-[40%] sm:top-[32%] md:top-[30%] left-4 md:left-12 w-full h-auto hidden sm:max-w-[220px] md:max-w-[300px] sm:block"
        />

        <button className="absolute top-[68%]  sm:top-[50%] md:top-[90%] left-4 sm:left-72 md:left-12 w-[80%] sm:w-[250px] md:w-[300px] h-[40px] sm:h-[50px] md:h-[63px] bg-[#121212] border border-[#FFFFFF33] text-[#FFFFFF] mx-auto md:mx-0 flex items-center justify-center text-center md:text-left text-xs sm:text-sm md:text-base">
          <a
            // href="https://calendly.com/cellfawarenessproject/fearlessnessfrequency"
            // target="_blank"
            // rel="noopener noreferrer"
            href="#bottomForm"
          >
            Reserve your Seat
          </a>
        </button>
      </div>

      <div className="w-full py-10 px-4 bg-[#121212]">
        <div className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-beautique font-medium text-[#FFFFFF] text-center mb-6 md:mb-10">
          In this Masterclass we will cover
        </div>
        <div className="w-full flex flex-col gap-4 md:flex-row md:gap-6 lg:gap-8 justify-center">
          {[
            {
              title: "Understanding the 12 Laws of Physics in Manifestation",
              description:
                "This section will break down each of the 12 laws in simple, relatable terms. You'll learn how these fundamental principles of physics are not just abstract concepts but can be applied to the art of manifestation, allowing you to harness the laws of the universe to bring your desires to life.",
            },
            {
              title: "Practical Application Without Science Jargon",
              description:
                "No science degree needed! We’ll explore how these laws influence everyday life and manifestation practices in an easy-to-understand manner. You’ll gain insights into how to align your thoughts and actions with these universal laws to effectively manifest your goals.",
            },
            {
              title: "Full-Proof Framework for Achieving Goals",
              description:
                "Learn a step-by-step framework that incorporates the laws of physics to create a reliable plan for success. This framework will guide you in setting and achieving any goal by using manifestation techniques grounded in these universal principles, ensuring consistent and predictable results.",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="w-full md:w-[480px] bg-[#FFFFFF] text-[#121212] px-5 py-6 flex flex-col justify-center text-center gap-4"
            >
              <div className="text-lg sm:text-xl md:text-2xl font-beautique font-medium">
                {item.title}
              </div>
              <div className="text-sm sm:text-sm md:text-base text-[#121212CC]">
                {item.description}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex flex-col lg:flex-row py-10 md:py-16 px-4 bg-[#121212] text-[#FFFFFF]">
        <div className="w-full lg:w-[728px] flex flex-col gap-4 px-4 md:px-6">
          <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-beautique text-center md:text-left leading-7 md:leading-9">
            PRESENTED BY MELISSA LAPLANTE
          </div>
          <div className="text-lg sm:text-xl md:text-xl font-medium text-center md:text-left">
            FOUNDER & CEO, KEYNOTE SPEAKER, AUTHOR
          </div>
          <p className="text-sm sm:text-base md:text-lg leading-6 md:leading-7 text-center md:text-left">
            Presented by Melissa LaPlante, Founder & CEO Keynote Speaker |
            Author. Meet your webinar host, Melissa—a trailblazer with over a
            decade of experience in coaching and empowering individuals to
            unlock their full potential. With a passion for helping others
            overcome self-doubt, fear of failure, perfectionism, and more,
            Melissa is your guide to breaking free from the chains that hold you
            back. Her journey from adversity to triumph fuels her mission.
            Melissa's approach is transformative, actionable, and incredibly
            empowering. She's here to help you conquer your fears, embrace your
            strengths, and shine your unique light on the world. Get ready to
            embark on a journey of growth and self-discovery with Melissa at the
            helm!
          </p>
        </div>
        <img
          src="/img/signature_005.JPG"
          alt="hero"
          className="w-full md:w-[50%] object-cover h-auto mt-6 lg:mt-0 lg:ml-6"
        />
      </div>

      <div className="w-full lg:relative h-auto bg-[#121212] p-[14px]">
        <img
          src="/img/fearless-img.jpeg"
          alt="bg"
          className="w-full h-auto object-cover hidden lg:block"
        />
        <div className="max-w-[653px] p-4 flex flex-col gap-10 bg-[#FFFFFF] lg:absolute top-[10%] left-[6%] mx-auto h-auto">
          <h2 className="text-[24px] md:text-[32px] leading-10 font-beautique font-semibold text-[#121212] text-center">
            I am ready to be Fearless & Succeed
          </h2>
          <form
            className="flex flex-col gap-4 w-full"
            id="bottomForm"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex-1 flex flex-col gap-2">
                <label
                  htmlFor="firstName"
                  className="text-sm font-medium text-[#121212]"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-[#1212120D] h-12"
                  required
                />
              </div>
              <div className="flex-1 flex flex-col gap-2">
                <label
                  htmlFor="lastName"
                  className="text-sm font-medium text-[#121212]"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-[#1212120D] h-12"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label
                htmlFor="email"
                className="text-sm font-medium text-[#121212]"
              >
                Enter your Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 bg-[#1212120D] h-12"
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <label
                htmlFor="thoughts"
                className="text-sm font-medium text-[#121212]"
              >
                Tell us what you most want to manifest in life?
              </label>
              <textarea
                id="thoughts"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-3 py-2 bg-[#1212120D] h-[126px] resize-none"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full h-10 p-2 bg-[#121212] text-[#FFFFFF]"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Masterclass;
