// import { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { auth } from "../firebase";
// import { IoCartOutline } from "react-icons/io5";

// const Navbar = (props) => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const { pathname } = useLocation();
//   const navigate = useNavigate();

//   const { loggedUser, cartData } = props;
//   const [totalQty, setTotalQty] = useState(0);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   useEffect(() => {
//     if (isMenuOpen) {
//       document.body.style.overflow = "hidden";
//     } else {
//       document.body.style.overflow = "auto";
//     }

//     return () => {
//       document.body.style.overflow = "auto";
//     };
//   }, [isMenuOpen]);

//   const handleLogout = () => {
//     auth.signOut().then(() => {
//       navigate("/login");
//       localStorage.clear();
//     });
//   };

//   useEffect(() => {
//     const temp = cartData.reduce(
//       (sum, cartItem) => sum + (cartItem?.quantity || 0),
//       0
//     );
//     setTotalQty(temp);
//   }, [cartData]);

//   return (
//     <div className="fixed top-0 left-0 w-full h-14 sm:h-[80px] px-4 py-0 text-[#FFFFFF] border-b-[1px #121212] z-50 bg-[#1212124D] flex flex-col md:flex-row items-end md:items-center justify-between">
//       <div className="w-full flex justify-between items-center py-0 sm:py-4">
//         <Link to="/" className="flex items-center gap-2">
//           <img src="img/Logo.svg" alt="logo" className="h-12" />
//           <div>
//             <div className="font-beautique font-bold text-3xl md:text-4xl">
//               CELLF
//             </div>
//             <div className="text-xs">Awareness project LLC</div>
//           </div>
//         </Link>

//         {/* Menu Toggle Button for Mobile */}
//         <button className="md:hidden text-white" onClick={toggleMenu}>
//           <GiHamburgerMenu />
//         </button>
//       </div>

//       {/* Overlay and Menu for Mobile */}
//       <div
//         className={`fixed inset-0 z-50 bg-black bg-opacity-75 flex ${
//           isMenuOpen ? "block" : "hidden"
//         } md:hidden`}
//       >
//         {/* Sidebar Menu */}
//         <div className="bg-[#121212] w-3/4 max-w-[300px] h-full flex flex-col">
//           {/* Logo */}
//           <div className="flex justify-between items-center p-4 border-b border-gray-700">
//             <Link to="/" className="flex items-center gap-2">
//               <img src="img/Logo.svg" alt="logo" className="h-12" />
//               <div>
//                 <div className="font-beautique font-bold text-3xl md:text-4xl">
//                   CELLF
//                 </div>
//                 <div className="text-xs">Awareness project LLC</div>
//               </div>
//             </Link>
//             <button onClick={toggleMenu} className="text-white">
//               &#10005; {/* Close icon */}
//             </button>
//           </div>

//           {/* Menu Items */}
//           <nav className="flex-1 flex flex-col p-4">
//             <Link
//               to="/"
//               className="p-3 text-center hover:underline rounded transition-colors"
//               onClick={() => setIsMenuOpen(false)}
//             >
//               Home
//             </Link>
//             <Link
//               to="/blog"
//               className="p-3 text-center hover:underline rounded transition-colors"
//               onClick={() => setIsMenuOpen(false)}
//             >
//               Blog
//             </Link>
//             <Link
//               to="/podcasts"
//               className="p-3 text-center hover:underline rounded transition-colors"
//               onClick={() => setIsMenuOpen(false)}
//             >
//               Podcasts
//             </Link>
//             <Link
//               to="/masterClass"
//               className="p-3 text-center hover:underline rounded transition-colors"
//               onClick={() => setIsMenuOpen(false)}
//             >
//               Masterclass
//             </Link>
//             <Link
//               to="/shop"
//               className="p-3 text-center hover:underline rounded transition-colors"
//               onClick={() => setIsMenuOpen(false)}
//             >
//               Shop
//             </Link>

//             {loggedUser ? (
//               <>
//                 <button
//                   onClick={handleLogout}
//                   className="p-3 text-center hover:underline rounded transition-colors"
//                 >
//                   Logout
//                 </button>
//                 <div
//                   className="relative cursor-pointer flex items-center justify-center"
//                   onClick={() => {
//                     navigate("/cart");
//                     setIsMenuOpen(false);
//                   }}
//                 >
//                   <IoCartOutline className="h-10 w-10" />
//                   <div className="rounded-full bg-[#FFFFFF] text-[#121212] w-5 h-5 flex items-center justify-center absolute top-[-3px] right-[100px]">
//                     {totalQty}
//                   </div>
//                 </div>
//               </>
//             ) : (
//               <>
//                 <Link
//                   to="/login"
//                   className="p-3 text-center hover:decoration-solid rounded transition-colors"
//                   onClick={() => setIsMenuOpen(false)}
//                 >
//                   Login
//                 </Link>
//                 <Link
//                   to="/signup"
//                   className="p-3 text-center hover:decoration-solid rounded transition-colors"
//                   onClick={() => setIsMenuOpen(false)}
//                 >
//                   Signup
//                 </Link>
//               </>
//             )}
//           </nav>
//         </div>
//       </div>

//       {/* Full Menu for MD and Above Screens */}
//       <div className="hidden md:flex md:flex-row md:items-center md:gap-3">
//         <Link to="/" className="p-3 hover:underline">
//           Home
//         </Link>
//         <Link to="/blog" className="p-3 hover:underline">
//           Blog
//         </Link>
//         <Link to="/podcasts" className="p-3 hover:underline">
//           Podcasts
//         </Link>
//         <Link to="/masterClass" className="p-3 hover:underline">
//           Masterclass
//         </Link>
//         <Link to="/shop" className="p-3 hover:underline">
//           Shop
//         </Link>

//         {loggedUser ? (
//           <>
//             <button onClick={handleLogout} className="p-3 hover:underline">
//               Logout
//             </button>
//             <div
//               className="relative cursor-pointer"
//               onClick={() => navigate("/cart")}
//             >
//               <IoCartOutline className="h-10 w-10" />
//               <div className="rounded-full bg-[#FFFFFF] text-[#121212] w-5 h-5 justify-center flex items-center absolute top-[-5px] right-[-3px]">
//                 {totalQty}
//               </div>
//             </div>
//           </>
//         ) : (
//           <>
//             <Link to="/login" className="p-3 hover:underline">
//               Login
//             </Link>
//             <Link to="/signup" className="p-3 hover:underline">
//               Signup
//             </Link>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Navbar;
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCartOutline } from "react-icons/io5";
import { auth } from "../firebase";

const Navbar = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { loggedUser, cartData } = props;
  const [totalQty, setTotalQty] = useState(0);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenuOpen]);

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate("/login");
      localStorage.clear();
    });
  };

  useEffect(() => {
    const temp = cartData.reduce(
      (sum, cartItem) => sum + (cartItem?.quantity || 0),
      0
    );
    setTotalQty(temp);
  }, [cartData]);

  return (
    <div className="fixed top-0 left-0 w-full h-14 sm:h-[80px] px-4 py-0 text-[#FFFFFF] border-b-[1px #121212] z-50 bg-[#1212124D] flex flex-col md:flex-row items-end md:items-center justify-between">
      <div className="w-full flex justify-between items-center py-0 sm:py-4">
        <Link to="/" className="flex items-center gap-2">
          <img src="img/Logo.svg" alt="logo" className="h-12" />
          <div>
            <div className="font-beautique font-bold text-3xl md:text-4xl">
              CELLF
            </div>
            <div className="text-xs">Awareness project LLC</div>
          </div>
        </Link>

        {/* Menu Toggle Button for Mobile */}
        <button className="md:hidden text-white" onClick={toggleMenu}>
          <GiHamburgerMenu />
        </button>
      </div>

      {/* Overlay and Menu for Mobile */}
      <div
        className={`fixed inset-0 z-50 bg-black bg-opacity-75 flex ${
          isMenuOpen ? "block" : "hidden"
        } md:hidden`}
      >
        {/* Sidebar Menu */}
        <div className="bg-[#121212] w-3/4 max-w-[300px] h-full flex flex-col">
          {/* Logo */}
          <div className="flex justify-between items-center p-4 border-b border-gray-700">
            <Link to="/" className="flex items-center gap-2">
              <img src="img/Logo.svg" alt="logo" className="h-12" />
              <div>
                <div className="font-beautique font-bold text-3xl md:text-4xl">
                  CELLF
                </div>
                <div className="text-xs">Awareness project LLC</div>
              </div>
            </Link>
            <button onClick={toggleMenu} className="text-white">
              &#10005; {/* Close icon */}
            </button>
          </div>

          {/* Menu Items */}
          <nav className="flex-1 flex flex-col p-4">
            <Link
              to="/"
              className="p-3 text-center hover:underline rounded transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              Home
            </Link>
            <Link
              to="/blog"
              className="p-3 text-center hover:underline rounded transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              Blog
            </Link>

            {/* Podcasts Dropdown */}
            <div className="relative">
              <button
                className="p-3 text-center hover:underline rounded transition-colors w-full text-left"
                onClick={toggleDropdown}
              >
                Podcasts
              </button>
              {isDropdownOpen && (
                <div className="absolute left-0 bg-[#121212] border-t border-gray-700 w-full">
                  <Link
                    to="/podcasts/audio"
                    className="p-3 hover:underline block text-center"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Audio Podcasts
                  </Link>
                  <Link
                    to="/podcasts/video"
                    className="p-3 hover:underline block text-center"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Video Podcasts
                  </Link>
                </div>
              )}
            </div>

            <Link
              to="/masterClass"
              className="p-3 text-center hover:underline rounded transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              Masterclass
            </Link>
            <Link
              to="/shop"
              className="p-3 text-center hover:underline rounded transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              Shop
            </Link>

            {loggedUser ? (
              <>
                <button
                  onClick={handleLogout}
                  className="p-3 text-center hover:underline rounded transition-colors"
                >
                  Logout
                </button>
                <div
                  className="relative cursor-pointer flex items-center justify-center"
                  onClick={() => {
                    navigate("/cart");
                    setIsMenuOpen(false);
                  }}
                >
                  <IoCartOutline className="h-10 w-10" />
                  <div className="rounded-full bg-[#FFFFFF] text-[#121212] w-5 h-5 flex items-center justify-center absolute top-[-3px] right-[100px]">
                    {totalQty}
                  </div>
                </div>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="p-3 text-center hover:underline rounded transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Login
                </Link>
                <Link
                  to="/signup"
                  className="p-3 text-center hover:underline rounded transition-colors"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Signup
                </Link>
              </>
            )}
          </nav>
        </div>
      </div>

      {/* Full Menu for MD and Above Screens */}
      <div className="hidden md:flex md:flex-row md:items-center md:gap-3">
        <Link to="/" className="p-3 hover:underline">
          Home
        </Link>
        <Link to="/blog" className="p-3 hover:underline">
          Blog
        </Link>

        {/* Podcasts Dropdown for Desktop */}
        <div className="relative group">
          <button className="p-3 hover:underline">Podcasts</button>
          <div className="absolute hidden group-hover:block bg-[#EEEEEE] w-40 py-2 text-[#121212]">
            <Link
              to="/podcasts/audio"
              className="block px-4 py-2 hover:underline"
            >
              Audio Podcasts
            </Link>
            <Link
              to="/podcasts/video"
              className="block px-4 py-2 hover:underline"
            >
              Video Podcasts
            </Link>
          </div>
        </div>

        <Link to="/masterClass" className="p-3 hover:underline">
          Masterclass
        </Link>
        <Link to="/shop" className="p-3 hover:underline">
          Shop
        </Link>

        {loggedUser ? (
          <>
            <button onClick={handleLogout} className="p-3 hover:underline">
              Logout
            </button>
            <div
              className="relative cursor-pointer"
              onClick={() => navigate("/cart")}
            >
              <IoCartOutline className="h-10 w-10" />
              <div className="rounded-full bg-[#FFFFFF] text-[#121212] w-5 h-5 justify-center flex items-center absolute top-[-5px] right-[-3px]">
                {totalQty}
              </div>
            </div>
          </>
        ) : (
          <>
            <Link to="/login" className="p-3 hover:underline">
              Login
            </Link>
            <Link to="/signup" className="p-3 hover:underline">
              Signup
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
