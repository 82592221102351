import React, { useState, useEffect } from "react";
import {
  usePayPalScriptReducer,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";

const initialOptions = {
  clientId:
    "Af8cmRn8_x5kTvqQIbnavwDWovyyRK4GUZR_-InmjIJ1x6MzIVukmpza8BDECT7rQU5ohMTAPGeGLD_J",
  currency: "USD",
  intent: "capture",
};

function CustomPayPalButton({ amount }) {
  const [{ isResolved, isPending }, paypalDispatch] = usePayPalScriptReducer();
  const [showPayPal, setShowPayPal] = useState(false);

  useEffect(() => {
    if (isResolved && !isPending && showPayPal) {
      loadPayPalButtons();
    }
  }, [isResolved, isPending, showPayPal]);

  const loadPayPalButtons = () => {
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: amount.toString(),
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log("Payment successful", order);
          // Handle post-payment logic here
          setShowPayPal(false); // Hide PayPal buttons after successful payment
        },
        onError: (err) => {
          console.error("PayPal error", err);
          // Handle errors here
          setShowPayPal(false); // Hide PayPal buttons on error
        },
        onCancel: () => {
          console.log("Payment cancelled");
          setShowPayPal(false); // Hide PayPal buttons if user cancels
        },
      })
      .render("#paypal-button-container");
  };

  const handlePayment = () => {
    if (isResolved && !isPending) {
      setShowPayPal(true);
    } else {
      paypalDispatch({
        type: "resetOptions",
        value: {
          clientId:
            "Af8cmRn8_x5kTvqQIbnavwDWovyyRK4GUZR_-InmjIJ1x6MzIVukmpza8BDECT7rQU5ohMTAPGeGLD_J",
          currency: "USD",
          intent: "capture",
        },
      });

      paypalDispatch({
        type: "setLoadingStatus",
        value: "pending",
      });
    }
  };

  return (
    <div>
      {!showPayPal && (
        <button
          className="w-full max-w-[249px] h-[63px] p-2 border border-[#FFFFFF99]"
          onClick={handlePayment}
          disabled={isPending}
          // style={{
          //   backgroundColor: '#0070ba',
          //   color: 'white',
          //   padding: '10px 20px',
          //   border: 'none',
          //   borderRadius: '5px',
          //   cursor: 'pointer'
          // }}
        >
          {isPending ? "Loading..." : "Buy Now"}
        </button>
      )}
      <div
        id="paypal-button-container"
        style={{ display: showPayPal ? "block" : "none" }}
      ></div>
    </div>
  );
}
function Checkout({ amount }) {
  return (
    <PayPalScriptProvider options={initialOptions}>
      <CustomPayPalButton amount={amount} />
    </PayPalScriptProvider>
  );
}

export default Checkout;
