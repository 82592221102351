import { useEffect, useState } from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Router from "./router/Router";
import { auth, db } from "./firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import "react-quill/dist/quill.snow.css";

function App() {
  const [cartData, setCartData] = useState([]);
  const [loggedUser, setLoggedUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userLogged) => {
      if (userLogged) {
        const getUsers = async () => {
          const q = query(
            collection(db, "users"),
            where("uid", "==", userLogged.uid)
          );
          const data = await getDocs(q);
          const users = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

          setLoggedUser(users[0]); // Set the logged user

          // Store user in localStorage
          if (users[0]) {
            localStorage.setItem("user", JSON.stringify(users[0]));
          }
        };
        getUsers();
      } else {
        console.log("No user is logged in");
        setLoggedUser(null);
      }
    });

    return () => unsubscribe(); // Clean up subscription
  }, []);

  return (
    <div className="">
      <Navbar loggedUser={loggedUser} cartData={cartData} />

      <Router
        cartData={cartData}
        setCartData={setCartData}
        loggedUser={loggedUser}
      />
      <Footer />
    </div>
  );
}

export default App;
