const DiscoveryCall = () => {
  return (
    <div className="pt-32 bg-[#121212] text-[#FFFFFF] p-2 w-full flex justify-center">
      <div className="w-5/6 flex flex-col gap-8">
        <div>
          <h1 className="text-4xl font-beautique font-bold leading-loose">
            FREE Discovery Call
          </h1>
          {/* <p className="text-xl leading-loose">
            Are you ready to stop feeling stuck?
          </p> */}
          <p className="text-xl leading-loose">30min Free online</p>
        </div>
        <div>
          <h2 className="text-3xl font-semibold leading-loose">
            Service Description
          </h2>
          <p className="text-xl tracking-wide">
            Imagine if you woke up this morning and you knew EXACTLY what you
            were capable of, what you do and do not want out of life, what you
            believe and value, and what your gift is to give to humanity. Not
            only that. You woke up fearless, confident, and capable, equipped
            with the tools to succeed in the face of any challenge.{" "}
            <a
              href="https://calendly.com/cellfawarenessproject/fearlessnessfrequency"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              BOOK YOUR DISCOVERY CALL
            </a>{" "}
            today and take a step towards that reality!
          </p>
        </div>
        {/* <div>
          <h2 className="text-3xl font-semibold leading-loose">
            Cancellation Policy
          </h2>
          <p className="text-xl tracking-wide">
            Thank you for your interest in our coaching program! Please read the
            following policy before making a purchase:
          </p>
          <div>
            <ul className=" flex flex-col gap-8 py-8">
              <li className="">
                <h2 className="text-xl font-bold">1. Payment : </h2>
                <p className="text-lg pt-1">
                  The total fee for the coaching program is $5000, payable in
                  full at the time of purchase.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">2. Refund Policy : </h2>
                <p className="text-lg pt-1">
                  Refunds will only be issued within 3 days of the date of
                  purchase. After this period, the coaching program fee is
                  nonrefundable.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">3. Cancellation : </h2>
                <p className="text-lg pt-1">
                  If you wish to cancel your participation in the coaching
                  program within the 3-day refund period, please contact us at
                  info@cellfawareness.com to request a refund.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">4. Transferability : </h2>
                <p className="text-lg pt-1">
                  The coaching program fee is non-transferable to another
                  individual or program.
                </p>
              </li>
              <li className="flex flex-col gap-2">
                <h2 className="text-xl font-bold">5. Program Details : </h2>
                <p className="text-lg">
                  <strong>Duration :</strong> 18 weeks consisting of 36 Coaching
                  sessions (18 weekly Group Sessions and 18 weekly one-on-one
                  sessions)
                </p>
                <h3 className="text-lg font-bold">Modules</h3>
                <ul className="pl-8 flex flex-col gap-2 text-lg">
                  <li>
                    <p>
                      <strong>Module 1:</strong> Discovering Your CELLF (Weeks
                      1-4)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Module 2:</strong> Building Your Business
                      Foundation (Weeks 5-8)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Module 3:</strong> Developing Your Product/Service
                      (Weeks 9-12)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Module 4:</strong> Launching and Growing Your
                      Business (Weeks 13-18)
                    </p>
                  </li>
                </ul>
                <h3 className="text-lg font-bold">Features</h3>
                <ul className="pl-8 flex flex-col gap-2 text-lg">
                  <li>Self-discovery exercises</li>
                  <li>Strategic planning sessions</li>
                  <li>Product development guidance</li>
                  <li>Launch and growth strategies</li>
                  <li>
                    Emphasis on authenticity, purpose, continuous learning, and
                    community building.
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="text-xl font-bold">6. Contact Information: </h2>
                <p className="text-lg pt-1">
                  If you have any questions or concerns about the coaching
                  program or this policy, please contact us at
                  info@cellfawareness.com
                </p>
              </li>
            </ul>
          </div>
          <p className="text-xl tracking-wide">
            By purchasing our coaching program, you acknowledge that you have
            read, understood, and agreed to the above policy.
          </p>
          <p className="text-xl tracking-wide">
            Thank you for choosing our coaching program. We look forward to
            supporting you on your journey to Fearlessly Authentic
            Entrepreneurship.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default DiscoveryCall;
