import { CiHeart } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { BlogData } from "../constants/BlogData";
import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../components/AlertMessage";
import ReactQuill from "react-quill";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import Loader from "../components/Loader";

const Blog = ({ loggedUser }) => {
  const Navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [BlogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [author, setAuthor] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [views, setViews] = useState(0);
  const [comments, setComments] = useState(0);
  const [likes, setLikes] = useState(0);

  const [isBlogModal, setIsBlogModal] = useState(false);
  const [readTime, setReadTime] = useState(0);

  const [editingBlogId, setEditingBlogId] = useState(null);

  const [imagePreview, setImagePreview] = useState(null);

  const [isBlogListUpdate, setIsBlogListUpdate] = useState(false);

  const [date, setDate] = useState(null);

  //get all blogs
  useEffect(() => {
    const getblogs = async () => {
      setLoading(true);
      try {
        const blogsArray = [];
        const path = "blogs";

        const querySnapshot = await getDocs(collection(db, path));
        querySnapshot.forEach((doc) => {
          blogsArray.push({ ...doc.data(), id: doc.id });
        });

        // Sort blogs by createdAt in descending order
        blogsArray.sort((a, b) => {
          return b.createdAt - a.createdAt; // latest first
        });

        setBlogData(blogsArray);
      } catch (error) {
        alertErrorMessage("Error fetching blogs:", error.message);
      } finally {
        setLoading(false);
      }
    };

    getblogs();
  }, [isBlogListUpdate]);
  ////

  // pagination
  const postsPerPage = 5;
  const pagesVisited = currentPage * postsPerPage;

  const paginatedPosts = BlogData.slice(
    pagesVisited,
    pagesVisited + postsPerPage
  );

  const pageCount = Math.ceil(BlogData.length / postsPerPage);

  const changePage = ({ selected }) => {
    setCurrentPage(selected);
  };
  //  //

  //upload image
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // const fileInput = e.target;

    if (file) {
      // const fileType = file.type;
      // const validTypes = ["image/jpeg", "image/jpg", "image/svg+xml"];

      // if (!validTypes.includes(fileType)) {
      //   alert("Please upload a valid image file (JPG, JPEG, SVG).");
      //   fileInput.value = null; // Reset the input value
      //   setImagePreview(null);
      //   return;
      // } else{
      setImage(file);
      const imageUrl = URL.createObjectURL(file); // Create a Blob URL
      setImagePreview(imageUrl); // Set the Blob URL for preview
      // }
    }
  };

  //

  // Function to calculate read time
  const calculateReadTime = (content) => {
    const wordsPerMinute = 200; // Average reading speed
    const words = content.split(/\s+/).filter(Boolean).length; // Count words
    const minutes = Math.ceil(words / wordsPerMinute); // Calculate read time
    return minutes > 0 ? minutes : 1; // Ensure at least 1 minute
  };

  // Function to handle content change
  const handleContentChange = (newContent) => {
    setContent(newContent);
    const estimatedReadTime = calculateReadTime(newContent);
    setReadTime(estimatedReadTime); // Store the read time in state
  };

  // store upload image in firebase storage
  const storage = getStorage();

  const uploadImage = async (file) => {
    console.log(file, "ff");
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };
  //

  // Submit all fields to the server and send them to the server with the data they contain in the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsBlogModal(false);

    try {
      let imageURL = "";

      if (image && !editingBlogId) {
        imageURL = await uploadImage(image);
      }

      const blogData = {
        title,
        image: imageURL ? imageURL : image, // Use the new URL if uploading, otherwise keep the existing one
        author,
        summary,
        views,
        comments,
        likes,
        content,
        readTime,
        createdAt: !editingBlogId ? Timestamp.now() : date, //
      };

      if (editingBlogId) {
        // Update existing blog
        await updateDoc(doc(db, "blogs", editingBlogId), blogData);
        alertSuccessMessage("Blog updated successfully");
        setIsBlogListUpdate(true);
      } else {
        // Add new blog
        const docRef = await addDoc(collection(db, "blogs"), blogData);
        alertSuccessMessage("Blog published successfully");
        setIsBlogListUpdate(true);
      }

      // Clear form fields
      setTitle("");
      setImage(null);
      setAuthor("");
      setSummary("");
      setContent("");
      setEditingBlogId(null); // Reset the editing state
    } catch (error) {
      console.error("Error saving blog: ", error);
      alertErrorMessage("Failed to save blog");
    } finally {
      setLoading(false);
    }
  };

  console.log(BlogData);

  // // //

  // delete blog

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this blog?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "blogs", id)); // Delete the document by ID
        alertSuccessMessage("Blog deleted successfully");
        // Optionally, you can refresh the blog list
        setBlogData((prev) => prev.filter((blog) => blog?.id !== id));
      } catch (error) {
        console.error("Error deleting blog:", error);
        alertErrorMessage("Failed to delete blog");
      }
    }
  };

  //edit blog
  const handleEdit = (blog) => {
    setViews(blog?.views);
    setComments(blog?.comments);
    setLikes(blog?.likes);
    setTitle(blog?.title);
    setImage(blog?.image);
    setAuthor(blog?.author);
    setSummary(blog?.summary);
    setContent(blog?.content);
    setDate(blog?.createdAt);
    setIsBlogModal(true);
    setEditingBlogId(blog.id);
    setIsBlogListUpdate(false);
  };

  //add blog
  const handleAdd = () => {
    setViews(0);
    setComments(0);
    setLikes(0);
    setTitle("");
    setImage(null);
    setImagePreview(null); // Reset the image preview state
    setAuthor("");
    setSummary("");
    setContent("");
    setDate(null);
    setIsBlogModal(true);
    setEditingBlogId("");
    setIsBlogListUpdate(false);
  };

  const [imageUrl, setImageUrl] = useState("");

  // Handle image upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `avatars/admin/${file.name}`);

      // Upload the image to Firebase Storage
      setLoading(true);
      uploadBytes(storageRef, file)
        .then(() => {
          // Get the download URL after successful upload
          return getDownloadURL(storageRef);
        })
        .then(async (url) => {
          setImageUrl(url); // Store the download URL

          // Save the URL to Firestore under a specific document for admin
          await setDoc(
            doc(db, "settings", "adminAvatar"),
            { avatar: url },
            { merge: true }
          );
          alertSuccessMessage("Avatar updated successfully");
        })
        .catch((error) => {
          console.error("Error uploading image: ", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Fetch avatar image from Firestore
  const fetchAvatar = async () => {
    const docRef = doc(db, "settings", "adminAvatar");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setImageUrl(docSnap.data().avatar);
    } else {
      console.log("No such document!");
    }
  };

  // UseEffect to fetch avatar on component mount
  useEffect(() => {
    fetchAvatar();
  }, []);

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)] backdrop-blur-sm flex justify-center z-[999] items-center">
          <div className="flex justify-center items-center">
            <Loader loading={loading} color="#FFFFFF33" />
          </div>
        </div>
      )}
      <div className="w-full bg-[#121212] flex flex-col justify-center items-center">
        <div className="md:h-[616px] h-96 w-full flex justify-center pt-[100px] pb-14">
          <img src="img/hero-bg.svg" alt="hero" className="w-full h-auto" />
        </div>

        <div className="h-auto bg-[#FFFFFF1A] w-full flex flex-col items-center justify-center py-24 px-4 md:px-8">
          <div className="text-center text-[#FFFFFF]">
            <div className="text-[24px] md:text-[32px] leading-[36px] md:leading-[48px] tracking-widest mb-4">
              UNLOCK YOUR FEARLESSNESS FREQUENCY
            </div>
            <p className="leading-7 text-center tracking-wide xl:mx-60 lg:mx-32 md:mx-1 sm:mx-0">
              Join our community for exclusive access to the latest blog posts,
              special deals, upcoming podcast episodes, a complimentary digital
              copy of my book, and much more! Sign up now and elevate your
              journey with us.
            </p>
          </div>
          <div className="mt-8 flex flex-col gap-4 md:flex-row md:gap-6 items-center">
            {/* <input className="w-full md:w-[270px] px-3 py-2 border border-[#FFFFFF] bg-[#FFFFFF] text-[#121212CC]" placeholder="Enter your email..."/> */}
            <button
              className="w-full md:w-[115px] bg-[#0DECD9] p-2 text-[#FFFFFF] mt-4 md:mt-0"
              onClick={() => Navigate("/signup")}
            >
              Sign up
            </button>
          </div>
        </div>

        <div className="w-5/6 pt-12 pb-4 flex flex-col gap-12 px-4 md:px-8">
          <div className="font-beautique font-medium text-3xl md:text-5xl text-[#FFFFFF] tracking-wider text-center mb-6">
            ALL POSTS
          </div>
          {loggedUser?.email == "admin@gmail.com" && (
            <div className="flex ml-auto gap-6">
              <button
                className=" bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
                onClick={() => handleAdd()}
              >
                Write Blog
              </button>
              <label className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition cursor-pointer">
                Upload Avatar
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  onClick={(e) => e.stopPropagation()}
                  className="hidden" // Hide the actual input
                />
              </label>
            </div>
          )}

          <div className="flex flex-col gap-6">
            {paginatedPosts.map((data, index) => (
              <div key={data?.id}>
                <Link
                  to={`/blogDetail/${data?.id}`}
                  className="w-full flex flex-col lg:flex-row gap-6 border border-solid border-[#FFFFFF33] bg-[#1E1E1E] rounded-md overflow-hidden items-center"
                >
                  <img
                    className="w-full lg:w-[312px] h-[200px] md:h-[228px] object-cover"
                    src={data?.image}
                    alt="blog"
                  />
                  <div className="flex-1 p-4 text-[#FFFFFF]">
                    <div className="text-xl md:text-2xl lg:text-3xl font-beautique font-medium leading-8 mb-2">
                      {data?.title}
                    </div>
                    <p
                      className="leading-6 md:leading-7 mb-4 text-sm md:text-base"
                      dangerouslySetInnerHTML={{ __html: data?.summary }}
                    ></p>
                    <div className="flex justify-between items-center border-t border-[#FFFFFF33] pt-4">
                      <div className="flex items-center gap-3">
                        {/* <div className="h-8 w-8 rounded-full bg-[#FFFFFF]"></div> */}
                        <div>
                          {imageUrl && (
                            <div
                              className="h-10 w-10 rounded-full bg-[#FFFFFF] flex items-center justify-center overflow-hidden"
                              style={{
                                backgroundImage: `url(${imageUrl})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            />
                          )}
                          {!imageUrl && <p>No avatar uploaded.</p>}
                        </div>
                        <div className="text-xs md:text-sm">
                          <div className="font-beautique font-bold">
                            {data?.author}
                          </div>
                          <div>1 day ago. {data?.readTime} min</div>
                        </div>
                      </div>
                      <div className="flex gap-2 md:gap-4 items-center text-xs md:text-sm">
                        <span>{data?.views} Views</span>
                        <span>{data?.comments} Comments</span>
                        <span>
                          <CiHeart />
                        </span>
                        <span>
                          <IoShareSocialOutline />
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
                {loggedUser?.email == "admin@gmail.com" && (
                  <div className="flex justify-end py-2 gap-2 text-[#FFFFFF]">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(data);
                      }}
                      className="bg-blue-500 py-1 px-3 rounded-md hover:bg-blue-600 transition"
                    >
                      Edit
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(data.id);
                      }}
                      className="bg-red-500 py-1 px-3 rounded-md hover:bg-red-600 transition"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationContainer"}
            previousLinkClassName={"paginationLink"}
            nextLinkClassName={"paginationLink"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>

      {/* Write Blog modal */}

      {isBlogModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.7)] flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-6xl w-full h-auto max-h-[90%] overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              {editingBlogId ? "Edit Blog" : "Write Blog"}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title:
                </label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Image:
                </label>
                {image ? (
                  <img
                    src={image}
                    alt="Current blog"
                    className="mb-2 w-full h-auto rounded-md"
                  />
                ) : imagePreview ? (
                  <img src={imagePreview} alt="Current blog" className="mb-2" />
                ) : (
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.svg"
                    onChange={handleImageUpload}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-300"
                  />
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Author:
                </label>
                <input
                  type="text"
                  value={author}
                  onChange={(e) => setAuthor(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Summary:
                </label>
                <ReactQuill
                  value={summary}
                  onChange={setSummary}
                  required
                  className="mt-1 rounded-md border border-gray-300 focus:ring focus:ring-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Content:
                </label>
                <ReactQuill
                  value={content}
                  onChange={handleContentChange}
                  required
                  className="mt-1 rounded-md border border-gray-300 focus:ring focus:ring-blue-300"
                />
              </div>
              <div className="flex justify-between mt-6">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-4 rounded-md text-white font-semibold ${
                    loading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
                  } transition`}
                >
                  {editingBlogId ? "Edit" : "Publish Blog"}
                </button>
                <button
                  type="button"
                  onClick={() => setIsBlogModal(false)}
                  className="py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Blog;
