// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBbJO1gPxF0mNk8OApyVKcep2JN5vUybTM',
  authDomain: 'cellf-a2be9.firebaseapp.com',
  projectId: 'cellf-a2be9',
  storageBucket: 'cellf-a2be9.appspot.com',
  messagingSenderId: '680716672383',
  appId: '1:680716672383:web:a32cb8b69e16aafea84e4a'
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);

