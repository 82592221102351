import { useState } from "react";
import { Masterclasssessions } from "../constants/Masterclasssessions";

const BusinessBlueprint = () => {
  const [allSessions, setAllSession] = useState(false);

  return (
    <div className="pt-32 bg-[#121212] text-[#FFFFFF] p-2 w-full flex justify-center">
      <div className="w-5/6 flex flex-col gap-8">
        <div className="">
          <h1 className="text-4xl font-beautique font-bold leading-loose">
            Purpose-Driven Business Blueprint
          </h1>
          <p className="text-xl leading-loose">
            Build and Launch Your Purpose-Driven Business in 18 Weeks
          </p>
          {/* <p className="text-xl leading-loose">$5000</p> */}
        </div>
        <div>
          <h2 className="text-3xl font-semibold leading-loose">
            Service Description
          </h2>
          <p className="text-xl tracking-wide">
            Join my transformative 18-week Group Masterclass for Purpose-Driven
            Entrepreneurs, where you'll embark on a journey of self-discovery,
            strategic business planning, product development, and successful
            launch strategies, all while staying true to your core values and
            building a supportive community to help you thrive in the
            entrepreneurial world."
          </p>
        </div>
        <a
          href="https://calendly.com/cellfawarenessproject/fearlessnessfrequency"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="img/master class.webp" alt="hero_img" className="w-1/2" />
        </a>
        <h3 className="text-2xl font-semibold leading-loose pb-5">
          Class Currently in session, set up a{" "}
          <a
            href="https://calendly.com/cellfawarenessproject/fearlessnessfrequency"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            DISCOVERY CALL
          </a>{" "}
          to find out about future enrollment dates.
        </h3>
        {/* <div>
          <h3 className="text-2xl font-semibold leading-loose pb-5">
            Upcoming sessions
          </h3>
          <div className="text-lg">
            <div>Dates : Sep 2, 2024 -Jan 6 2025</div>
            <div>Total of 19 sessions</div>
          </div>
        </div>
        <table
          border="1"
          cellPadding="10"
          className="w-1/2 text-center border-collapse border-[2px] border-[#FFFFFF33]"
        >
          <tbody>
            {allSessions
              ? Masterclasssessions.map((sessions) => {
                  return (
                    <tr key={sessions.id}>
                      <td className="p-4 text-center align-middle border-b border-b-[#FFFFFF33]">
                        {sessions.Date}
                      </td>
                      <td className="p-4 text-center align-middle border-b border-b-[#FFFFFF33]">
                        {sessions.Time}, {sessions.Duration}{" "}
                      </td>
                      <td className="p-4 text-center align-middle border-b border-b-[#FFFFFF33]">
                        {sessions.Instructor}
                      </td>
                    </tr>
                  );
                })
              : Masterclasssessions.slice(0, 7).map((sessions) => {
                  return (
                    <tr key={sessions.id}>
                      <td className="p-4 text-center align-middle border-b border-b-[#FFFFFF33]">
                        {sessions.Date}
                      </td>
                      <td className="p-4 text-center align-middle border-b border-b-[#FFFFFF33]">
                        {sessions.Time}, {sessions.Duration}{" "}
                      </td>
                      <td className="p-4 text-center align-middle border-b border-b-[#FFFFFF33]">
                        {sessions.Instructor}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {!allSessions && (
          <button
            className="underline text-left text-xl"
            onClick={() => setAllSession(true)}
          >
            Load all sessions
          </button>
        )} */}

        {/* <div>
          <h2 className="text-3xl font-semibold leading-loose">
            Cancellation Policy
          </h2>
          <p className="text-xl tracking-wide">
            Thank you for your interest in our coaching program! Please read the
            following policy before making a purchase:
          </p>
          <div>
            <ul className=" flex flex-col gap-8 py-8">
              <li className="">
                <h2 className="text-xl font-bold">1. Payment : </h2>
                <p className="text-lg pt-1">
                  The total fee for the coaching program is $5000, payable in
                  full at the time of purchase.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">2. Refund Policy : </h2>
                <p className="text-lg pt-1">
                  Refunds will only be issued within 3 days of the date of
                  purchase. After this period, the coaching program fee is
                  nonrefundable.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">3. Cancellation : </h2>
                <p className="text-lg pt-1">
                  If you wish to cancel your participation in the coaching
                  program within the 3-day refund period, please contact us at
                  info@cellfawareness.com to request a refund.
                </p>
              </li>
              <li>
                <h2 className="text-xl font-bold">4. Transferability : </h2>
                <p className="text-lg pt-1">
                  The coaching program fee is non-transferable to another
                  individual or program.
                </p>
              </li>
              <li className="flex flex-col gap-2">
                <h2 className="text-xl font-bold">5. Program Details : </h2>
                <p className="text-lg">
                  <strong>Duration :</strong> 18 weeks consisting of 36 Coaching
                  sessions (18 weekly Group Sessions and 18 weekly one-on-one
                  sessions)
                </p>
                <h3 className="text-lg font-bold">Modules</h3>
                <ul className="pl-8 flex flex-col gap-2 text-lg">
                  <li>
                    <p>
                      <strong>Module 1:</strong> Discovering Your CELLF (Weeks
                      1-4)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Module 2:</strong> Building Your Business
                      Foundation (Weeks 5-8)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Module 3:</strong> Developing Your Product/Service
                      (Weeks 9-12)
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Module 4:</strong> Launching and Growing Your
                      Business (Weeks 13-18)
                    </p>
                  </li>
                </ul>
                <h3 className="text-lg font-bold">Features</h3>
                <ul className="pl-8 flex flex-col gap-2 text-lg">
                  <li>Self-discovery exercises</li>
                  <li>Strategic planning sessions</li>
                  <li>Product development guidance</li>
                  <li>Launch and growth strategies</li>
                  <li>
                    Emphasis on authenticity, purpose, continuous learning, and
                    community building.
                  </li>
                </ul>
              </li>
              <li>
                <h2 className="text-xl font-bold">6. Contact Information: </h2>
                <p className="text-lg pt-1">
                  If you have any questions or concerns about the coaching
                  program or this policy, please contact us at
                  info@cellfawareness.com
                </p>
              </li>
            </ul>
          </div>
          <p className="text-xl tracking-wide">
            By purchasing our coaching program, you acknowledge that you have
            read, understood, and agreed to the above policy.
          </p>
          <p className="text-xl tracking-wide">
            Thank you for choosing our coaching program. We look forward to
            supporting you on your journey to Fearlessly Authentic
            Entrepreneurship.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default BusinessBlueprint;
