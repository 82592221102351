import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { alertErrorMessage, alertSuccessMessage } from "./AlertMessage";
import Loader from "./Loader";

const LoginModal = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setEmail("");
      setPassword("");
      setErrorMessage("");
      setLoading(false);
      navigate("/");
      alertSuccessMessage("Login successful");
    } catch (error) {
      setLoading(false);
      alertErrorMessage(`Error: ${error.message}`);
      console.error("Error during login:", error.message);
    }
  };

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)] backdrop-blur-sm flex justify-center z-[999] items-center">
          <div className="flex justify-center items-center">
            <Loader loading={loading} color="#FFFFFF33" />
          </div>
        </div>
      )}
      <div className="bg-[#121212] flex justify-center pt-[100px] px-4 sm:px-0">
        <form
          className="w-full max-w-[553px] h-auto sm:h-[520px] p-8 gap-12 bg-[#FFFFFF] text-[#121212] flex flex-col"
          onSubmit={handleLogin}
        >
          <h2 className="text-[28px] sm:text-[32px] leading-8 sm:leading-10 font-medium font-beautique text-center">
            Log in
          </h2>

          <div className="w-full h-auto gap-4">
            <div className="w-full h-[76px] gap-2">
              <div className="text-sm font-medium">Enter your Email</div>
              <input
                className="w-full h-12 py-2 px-3 border border-[#1212121A]"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="w-full h-[76px] gap-2">
              <div className="text-sm font-medium">Enter your Password</div>
              <input
                className="w-full h-12 py-2 px-3 border border-[#1212121A]"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </div>

          {errorMessage && (
            <div className="text-red-500 text-sm text-center">
              {errorMessage}
            </div>
          )}

          <button
            type="submit"
            className="w-full sm:w-[489px] h-10 p-8 bg-[#121212] text-[#FFFFFF] flex items-center justify-center"
          >
            Login
          </button>

          <div className="text-xs text-center mt-4">
            Don’t have an account?{" "}
            <Link to="/signup" className="underline cursor-pointer">
              Signup
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginModal;
