import { Link } from "react-router-dom";
// import { ProductData } from "../constants/ProductData";
import { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Loader from "../components/Loader";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../components/AlertMessage";
import ReactQuill from "react-quill";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const Shop = ({ loggedUser }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100);

  const [ProductData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isProductModal, setIsProductModal] = useState(false);

  // store upload image in firebase storage

  const storage = getStorage();

  const uploadImage = async (file) => {
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };
  //

  //
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [price, setPrice] = useState("");
  const [about, setAbout] = useState("");
  const [productInfo, setProductInfo] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [isProductListUpdate, setIsProductListUpdate] = useState(false);

  // Submit all fields to the server and send them to the server with the data they contain in the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsProductModal(false);

    try {
      let imageURL = "";

      if (image && !editingProductId) {
        imageURL = await uploadImage(image);
      }

      const productData = {
        name,
        image: imageURL ? imageURL : image, // Use the new URL if uploading, otherwise keep the existing one
        price,
        about,
        product_info: productInfo,
      };

      if (editingProductId) {
        // Update existing product
        await updateDoc(doc(db, "products", editingProductId), productData);
        alertSuccessMessage("Product updated successfully");
        setIsProductListUpdate(true);
      } else {
        // Add new product
        await addDoc(collection(db, "products"), productData);
        alertSuccessMessage("Product added successfully");
        setIsProductListUpdate(true);
      }

      // Clear form fields
      setName("");
      setImage(null);
      setPrice("");
      setAbout("");
      setProductInfo("");
      setEditingProductId(null); // Reset the editing state
    } catch (error) {
      console.error("Error saving product: ", error);
      alertErrorMessage("Failed to save product");
    } finally {
      setLoading(false);
    }
  };

  // // //

  //get all products data
  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      try {
        const productsArray = [];
        const path = "products";

        const querySnapshot = await getDocs(collection(db, path));
        querySnapshot.forEach((doc) => {
          productsArray.push({ ...doc.data(), id: doc.id });
        });

        setProductData(productsArray);
      } catch (error) {
        console.error("Error fetching products:", error.message);
      } finally {
        setLoading(false);
      }
    };

    getProducts();
  }, [isProductListUpdate]);
  ////

  // Filters

  const minProductPrice = Math.min(...ProductData.map((item) => item.price));
  const maxProductPrice = Math.max(...ProductData.map((item) => item.price));

  const filteredProducts = ProductData.filter((item) => {
    const itemNameMatch = item?.name
      ?.toLowerCase()
      .includes(searchTerm?.toLowerCase());
    const itemPriceMatch = item?.price >= minPrice && item?.price <= maxPrice;
    return itemNameMatch && itemPriceMatch;
  });

  const handleSliderChange = (value) => {
    setMaxPrice(Number(value));
  };

  const handleMinPriceChange = (e) => {
    const value = Number(e.target.value);
    setMinPrice(value);
  };

  const handleMaxPriceChange = (e) => {
    const value = Number(e.target.value);
    setMaxPrice(value);
  };

  //

  // When add/edit product modal open, then bg is not scrollable
  useEffect(() => {
    if (isProductModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Clean up function to remove the class on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isProductModal]);
  //

  //upload image
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // const fileInput = e.target;

    if (file) {
      // const fileType = file.type;
      // const validTypes = ["image/jpeg", "image/jpg", "image/svg+xml"];

      // if (!validTypes.includes(fileType)) {
      //   alert("Please upload a valid image file (JPG, JPEG, SVG).");
      //   fileInput.value = null; // Reset the input value
      //   setImagePreview(null);
      //   return;
      // } else{
      setImage(file);
      const imageUrl = URL.createObjectURL(file); // Create a Blob URL
      setImagePreview(imageUrl); // Set the Blob URL for preview
      // }
    }
  };
  //

  // delete product

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "products", id)); // Delete the document by ID
        alertSuccessMessage("Product deleted successfully");
        // Optionally, you can refresh the product list
        setProductData((prev) => prev.filter((product) => product?.id !== id));
      } catch (error) {
        console.error("Error deleting product:", error);
        alertErrorMessage("Failed to delete product");
      }
    }
  };

  const [editingProductId, setEditingProductId] = useState(null);

  //edit product
  const handleEdit = (product) => {
    setName(product.name);
    setImage(product.image);
    setPrice(product.price);
    setAbout(product.about);
    setProductInfo(product.product_info);
    setIsProductModal(true);
    setEditingProductId(product.id);
    setIsProductListUpdate(false);
  };

  //add product
  const handleAdd = () => {
    setName("");
    setImage("");
    setPrice("");
    setAbout("");
    setProductInfo("");
    setIsProductModal(true);
    setEditingProductId("");
    setIsProductListUpdate(false);
  };

  return (
    <div>
      {loading && (
        <div className="fixed top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)] backdrop-blur-sm flex justify-center z-[999] items-center">
          <div className="flex justify-center items-center">
            <Loader loading={loading} color="#FFFFFF33" />
          </div>
        </div>
      )}
      {/* <div className="h-auto w-full py-24 p-10 bg-[#121212] flex flex-col lg:flex-row lg:justify-center ">
      <div className="w-full lg:w-1/4 px-6 lg:px-3 flex flex-col gap-8 lg:gap-10 pt-44">

        <input
          className="w-full h-11 text-[#121212] placeholder:text-[#121212] py-0 px-3"
          placeholder="Search product..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className="w-full h-auto">
          <div className="font-beautique text-lg md:text-xl font-medium text-[#FFFFFF] mb-4">
            Filter by
          </div>
          <div className="text-[#FFFFFF] flex flex-col gap-4">
            <div className="text-lg md:text-xl leading-[32px] font-medium">
              Price
            </div>


            <input
              type="range"
              className="filter-input"
              min={minProductPrice}
              max={maxProductPrice}
              value={maxPrice}
              onChange={(e) => handleSliderChange(e.target.value)}
            />
            Max Price: ${maxPrice}

            
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-1">
                <span>From</span>
                <input
                  type="number"
                  className="text-[#121212] placeholder:text-[#121212] py-2 px-3 border border-[#1212121A] w-full"
                  placeholder={`$${minProductPrice}`}
                  value={minPrice}
                  onChange={handleMinPriceChange}
                  min={minProductPrice}
                  max={maxProductPrice}
                />
              </div>
              <div className="flex flex-col gap-1">
                <span>To</span>
                <input
                  type="number"
                  className="text-[#121212] placeholder:text-[#121212] py-2 px-3 border border-[#1212121A] w-full"
                  placeholder={`$${maxProductPrice}`}
                  value={maxPrice}
                  onChange={handleMaxPriceChange}
                  min={minPrice} 
                  max={maxProductPrice}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-3/4 py-4 lg:py-8 px-4 lg:px-6 flex flex-col gap-8 text-[#FFFFFF]">
        <div className="text-2xl lg:text-[32px] leading-8 md:leading-10 font-beautique font-medium text-center">
          All Products
        </div>
        <button className="ml-auto border p-2" onClick={() => handleAdd()}>Add Product</button>
        <div className="w-full flex flex-wrap gap-6 justify-center">
          {filteredProducts?.length > 0 ? (
            filteredProducts?.map((item) => (
              <>
              <Link
                to={`/productDetail/${item?.id}`}
                key={item?.id}
                className="w-full sm:w-[378px] h-auto flex flex-col gap-6"
              >
                <img
                  className="w-full h-auto max-h-[378px] object-cover bg-[#FFFFFF] min-h-[378px]"
                  src={item?.image}
                  alt="product_img"
                />
                <div className="flex flex-col gap-2 text-center sm:text-left">
                  <div className="font-beautique font-medium">{item?.name}</div>
                  <div className="font-medium">Price - ${item?.price}</div>
                </div>
              </Link>
              <button onClick={() => handleEdit(item)}>Edit</button>
              <button onClick={() => handleDelete(item.id)}>Delete</button>          
               </>
            ))
          ) : (
            <div className="text-white">No products found</div>
          )}
        </div>
      </div>
    </div> */}

      <div className="h-auto w-full py-24 p-10 bg-[#1E1E1E] flex flex-col lg:flex-row lg:justify-center">
        <div className="w-full lg:w-1/4 px-6 lg:px-3 flex flex-col gap-8 lg:gap-10 pt-10  sm:pt-44">
          <input
            className="w-full h-11 text-[#121212] placeholder:text-[#121212] py-0 px-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
            placeholder="Search product..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="w-full h-auto">
            <div className="font-beautique text-lg md:text-xl font-medium text-[#FFFFFF] mb-4">
              Filter by
            </div>
            <div className="text-[#FFFFFF] flex flex-col gap-4">
              <div className="text-lg md:text-xl leading-[32px] font-medium">
                Price
              </div>
              <input
                type="range"
                className="filter-input"
                min={minProductPrice}
                max={maxProductPrice}
                value={maxPrice}
                onChange={(e) => handleSliderChange(e.target.value)}
              />
              Max Price: ${maxPrice}
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  <span>From</span>
                  <input
                    type="number"
                    className="text-[#121212] placeholder:text-[#121212] py-2 px-3 border border-[#1212121A] w-full rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    placeholder={`$${minProductPrice}`}
                    value={minPrice}
                    onChange={handleMinPriceChange}
                    min={minProductPrice}
                    max={maxProductPrice}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span>To</span>
                  <input
                    type="number"
                    className="text-[#121212] placeholder:text-[#121212] py-2 px-3 border border-[#1212121A] w-full rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                    placeholder={`$${maxProductPrice}`}
                    value={maxPrice}
                    onChange={handleMaxPriceChange}
                    min={minPrice}
                    max={maxProductPrice}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-3/4 py-4 lg:py-8 px-4 lg:px-6 flex flex-col gap-8 text-[#FFFFFF]">
          <div className="text-2xl lg:text-[32px] leading-8 md:leading-10 font-beautique font-medium text-center">
            All Products
          </div>
          {loggedUser?.email == "admin@gmail.com" && (
            <button
              className="ml-auto bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
              onClick={() => handleAdd()}
            >
              Add Product
            </button>
          )}
          <div className="w-full flex flex-wrap gap-6 justify-center">
            {filteredProducts?.length > 0 ? (
              filteredProducts?.map((item) => (
                <div
                  key={item?.id}
                  className="relative w-full sm:w-[378px] flex flex-col  rounded-md overflow-hidden shadow-lg"
                >
                  <Link
                    to={`/productDetail/${item?.id}`}
                    className="flex flex-col"
                  >
                    <img
                      className="w-full h-[378px] object-cover" // Fixed height for consistency
                      src={item?.image}
                      alt="product_img"
                    />
                    <div className="flex flex-col gap-2 text-center sm:text-left p-4">
                      <div className="font-beautique font-medium line-clamp-1">
                        {item?.name}
                      </div>
                      <div className="font-medium">Price - ${item?.price}</div>
                    </div>
                  </Link>
                  {loggedUser?.email == "admin@gmail.com" && (
                    <div className="flex flex-col justify-between py-2 gap-2 ">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(item);
                        }}
                        className="bg-blue-500 py-1 px-3 rounded-md hover:bg-blue-600 transition"
                      >
                        Edit
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(item.id);
                        }}
                        className="bg-red-500 w-full py-1 px-3 rounded-md hover:bg-red-600 transition"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-white">No products found</div>
            )}
          </div>
        </div>
      </div>

      {/* Add product form */}

      {isProductModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.7)] flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-6xl w-full h-auto max-h-[90%] overflow-y-auto">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              {editingProductId ? "Edit Product" : "Add Product"}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Name:
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Image:
                </label>
                {image ? (
                  <img
                    src={image}
                    alt="Current blog"
                    className="mb-2 w-full h-auto rounded-md"
                  />
                ) : imagePreview ? (
                  <img src={imagePreview} alt="Current blog" className="mb-2" />
                ) : (
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.svg"
                    onChange={handleImageUpload}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-300"
                  />
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Price:
                </label>
                <input
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  onWheelCapture={(e) => {
                    e.target.blur();
                  }}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  About:
                </label>
                <ReactQuill
                  value={about}
                  onChange={setAbout}
                  required
                  className="mt-1 rounded-md border border-gray-300 focus:ring focus:ring-blue-300"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Product Info:
                </label>
                <ReactQuill
                  value={productInfo}
                  onChange={setProductInfo}
                  required
                  className="mt-1 rounded-md border border-gray-300 focus:ring focus:ring-blue-300"
                />
              </div>
              <div className="flex justify-between mt-6">
                <button
                  type="submit"
                  disabled={loading}
                  className={`py-2 px-4 rounded-md text-white font-semibold ${
                    loading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
                  } transition`}
                >
                  {editingProductId ? "Edit" : "Add Product"}
                </button>
                <button
                  type="button"
                  onClick={() => setIsProductModal(false)}
                  className="py-2 px-4 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 transition"
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Shop;
