import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { alertErrorMessage, alertSuccessMessage } from "./AlertMessage";
import emailjs from "emailjs-com";
import Loader from "./Loader";

const SignupModal = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPass, setShowPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState("");
  const [matchPassword, setMatchPassword] = useState("true");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();

    if (password === confirmPassword) {
      setLoading(true); // Start loading before async operations

      try {
        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        const initialcartvalue = 0;

        // Add user data to Firestore using user's UID as document ID
        await setDoc(doc(db, "users", user.uid), {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          cart: initialcartvalue,
          uid: user.uid,
        });

        // On success
        alertSuccessMessage("Registration successful!");
        sendWelcomeEmail(firstName, lastName, email);
        sendEmailToAdmin(firstName, lastName, email);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        navigate("/");
      } catch (error) {
        // Handle errors
        alertErrorMessage(`Error: ${error.message}`);
        console.error("Error during signup:", error.message);
      } finally {
        // Always stop loading after async operations (success or failure)
        setLoading(false);
      }
    } else {
      alertErrorMessage("Passwords do not match!");
      setMatchPassword("false");
      setLoading(false); // Ensure loading is stopped if passwords do not match
    }
  };

  const sendWelcomeEmail = (firstName, lastName, email) => {
    console.log(email);
    // Define template parameters (dynamic data passed to the email template)
    const templateParams = {
      user_name: firstName, // This should match the variable name in your EmailJS template
      user_email: email, // This should match the variable name in your EmailJS template
    };

    // Send the email using the EmailJS send method
    emailjs
      .send(
        "service_jmsnt7o", // Your Service ID
        "template_kak7uiq", // Your Template ID
        templateParams, // Dynamic template parameters (name, email, etc.)
        "NNBVqBuoUscbk00MR" // Your Public Key (User ID)
      )
      .then(
        (result) => {
          console.log("Email sent successfully!", result.text);
        },
        (error) => {
          console.error("Failed to send email", error.text);
        }
      );
  };

  const sendEmailToAdmin = (firstName, lastName, email) => {
    // Define template parameters (dynamic data passed to the email template)
    const templateParams = {
      type: "New user Registration",
      user_name: firstName + " " + lastName, // This should match the variable name in your EmailJS template
      user_email: email,
      user_message: "Since it's signup, no user message available",
    };

    // Send the email using the EmailJS send method
    emailjs
      .send(
        "service_jmsnt7o", // Your Service ID
        "template_4rjp3pb", // Your Template ID
        templateParams, // Dynamic template parameters (name, email, etc.)
        "NNBVqBuoUscbk00MR" // Your Public Key (User ID)
      )
      .then(
        (result) => {
          console.log("Email sent successfully!", result.text);
        },
        (error) => {
          console.error("Failed to send email", error.text);
        }
      );
  };

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)] backdrop-blur-sm flex justify-center z-[999] items-center">
          <div className="flex justify-center items-center">
            <Loader loading={loading} color="#FFFFFF33" />
          </div>
        </div>
      )}
      <div className="bg-[#121212] flex justify-center pt-[100px] px-4 sm:px-0">
        <form
          className="w-full max-w-[553px] h-auto sm:h-[592px] p-8 gap-9 bg-[#FFFFFF] text-[#121212] flex flex-col"
          onSubmit={handleSignup}
        >
          <h2 className="text-[28px] sm:text-[32px] leading-8 sm:leading-10 font-medium font-beautique text-center">
            Sign up
          </h2>

          <div className="w-full h-auto gap-4">
            <div className="w-full h-[152px] sm:h-[76px] gap-2 flex flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2">
                <div className="text-sm font-medium">First Name</div>
                <input
                  className="w-full h-12 py-2 px-3 border border-[#1212121A]"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="w-full sm:w-1/2">
                <div className="text-sm font-medium">Last Name</div>
                <input
                  className="w-full h-12 py-2 px-3 border border-[#1212121A]"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="w-full h-[76px] gap-2">
              <div className="text-sm font-medium">Enter your Email</div>
              <input
                className="w-full h-12 py-2 px-3 border border-[#1212121A]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="w-full h-[76px] gap-2">
              <div className="text-sm font-medium">Enter your Password</div>
              <div className="relative">
                <input
                  className="w-full h-12 py-2 px-3 border border-[#1212121A]"
                  type={showPass ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute right-2 top-3"
                  onClick={() => setShowPass(!showPass)}
                >
                  {showPass ? (
                    <i className="ri-eye-line"></i>
                  ) : (
                    <i className="ri-eye-off-line"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="w-full h-[76px] gap-2">
              <div className="text-sm font-medium">Re-enter your Password</div>
              <div className="relative">
                <input
                  className="w-full h-12 py-2 px-3 border border-[#1212121A]"
                  type={showConfirmPass ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setMatchPassword(
                      e.target.value === password ? true : false
                    );
                  }}
                  required
                />
                <button
                  type="button"
                  className="absolute right-2 top-3"
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                >
                  {showConfirmPass ? (
                    <i className="ri-eye-line"></i>
                  ) : (
                    <i className="ri-eye-off-line"></i>
                  )}
                </button>
              </div>
            </div>

            {confirmPassword && !matchPassword ? (
              <div className="text-red-500 text-sm font-medium">
                Password does not match
              </div>
            ) : (
              ""
            )}
          </div>

          <button
            className="w-full sm:w-[489px] h-10 p-8 bg-[#121212] text-[#FFFFFF] flex items-center justify-center"
            type="submit"
          >
            Signup
          </button>

          <div className="text-xs text-center mt-4">
            Already have an account?
            <Link to="/login" className="underline cursor-pointer">
              Login
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignupModal;
