import { CiHeart } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
// import { BlogData } from "../constants/BlogData";
import { useEffect, useState } from "react";
import {
  doc,
  DocumentSnapshot,
  getDoc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Loader from "../components/Loader";

const BlogDetail = () => {
  const [loading, setLoading] = useState(false);
  const [blog, setBlog] = useState(null);
  const [isViewUpdated, setIsViewUpdated] = useState(false);
  const { id } = useParams();

  // useEffect(() => {
  //     const increaseViews = async () => {
  //         const blogDoc = doc(db, 'blogs', id);
  //         await updateDoc(blogDoc, {
  //             views: increment(1) // Increment the views count by 1
  //         });
  //         setIsViewUpdated(true)
  //     };

  //     increaseViews();
  // }, [id]);

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted
    const increaseViews = async () => {
      if (isMounted) {
        console.log("Increasing views for blog id:", id);
        const blogDoc = doc(db, "blogs", id);
        await updateDoc(blogDoc, {
          views: increment(1),
        });
        setIsViewUpdated(true);
      }
    };

    increaseViews();

    return () => {
      isMounted = false; // Clean up the effect
    };
  }, [id]);

  // Fetch blog details
  useEffect(() => {
    const getBlog = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "blogs", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setLoading(false);
          setBlog(docSnap.data());
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog: ", error);
      }
    };
    getBlog();
  }, [id, isViewUpdated]);

  console.log(blog, "bro");

  // const Data = blog?.find(blog => blog?.id == id)

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)] backdrop-blur-sm flex justify-center z-[999] items-center">
          <div className="flex justify-center items-center">
            <Loader loading={loading} color="#FFFFFF33" />
          </div>
        </div>
      )}
      <div className="bg-[#121212] pt-[100px] w-full flex justify-center">
        <div className="text-[#FFFFFF] p-6 flex flex-col gap-8 w-5/6 items-center">
          <div className="w-full max-w-[1464px] h-10 font-beautique font-bold text-[24px] md:text-[32px] leading-10 pb-24">
            {blog?.title}
          </div>

          <img
            className="max-w-[350px] max-h-[453px]"
            src={blog?.image}
            alt="article_img"
          />

          <div className="w-full max-w-[1464px] h-fit">
            <div className="flex gap-3 items-center">
              <div className="h-8 w-8 rounded-full bg-[#FFFFFF]"></div>
              <div className="w-auto text-sm">
                <div className="font-beautique font-bold">{blog?.author}</div>
                <div>1 day ago · {blog?.readTime} min</div>
              </div>
            </div>

            <div
              className="w-full h-fit flex flex-col gap-6 py-6 text-base md:text-lg"
              dangerouslySetInnerHTML={{ __html: blog?.content }}
            />

            <div className="w-full sm:w-[262px] h-9 flex gap-4 items-center ml-auto">
              <span>{blog?.views} Views</span>
              <span>{blog?.comments} Comments</span>
              <span>
                <CiHeart />
              </span>
              <span>
                <IoShareSocialOutline />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
