import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { addDoc, collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../components/AlertMessage";
import {
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import Loader from "../components/Loader";
import Checkout from "../components/CustomPaypalButton";

const ProductDetail = () => {
  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState(null);
  const [allProductData, setAllProductData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [isInCart, setIsInCart] = useState(false);
  const [loading, setLoading] = useState(false);

  const loggedUserString = localStorage.getItem("user");
  const loggedUser = loggedUserString ? JSON.parse(loggedUserString) : null;

  const { id } = useParams();
  const Navigate = useNavigate();

  // Fetch cart data
  useEffect(() => {
    if (loggedUser) {
      const getCartData = async () => {
        try {
          const cartArray = [];
          const path = `cart-${loggedUser.uid}`;
          const querySnapshot = await getDocs(collection(db, path));
          querySnapshot.forEach((doc) => {
            cartArray.push({ ...doc.data(), id: doc.id });
          });
          setCartData(cartArray);
        } catch (error) {
          alertErrorMessage("Error fetching cart data: ", error.message);
        }
      };
      getCartData();
    }
  }, [loggedUser]);

  // Fetch product data
  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, "products", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setLoading(false);
          setProduct(docSnap.data());
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching product: ", error);
      }
    };
    getProduct();
  }, [id]);

  // Fetch all products data
  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      try {
        const productsArray = [];
        const path = "products";
        const querySnapshot = await getDocs(collection(db, path));
        querySnapshot.forEach((doc) => {
          productsArray.push({ ...doc.data(), id: doc.id });
        });
        setLoading(false);
        setAllProductData(productsArray);
      } catch (error) {
        setLoading(false);
        alertErrorMessage("Error fetching products: ", error.message);
      }
    };
    getProducts();
  }, []);

  // Check if the current product is in the cart
  useEffect(() => {
    if (product && cartData.length > 0) {
      const foundProduct = cartData.find(
        (item) => item.product.name == product.name
      );
      setIsInCart(!!foundProduct);
    }
  }, [product, cartData]);

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const addToCart = async () => {
    if (loggedUser) {
      setLoading(true);
      try {
        await addDoc(collection(db, `cart-${loggedUser.uid}`), {
          product: product,
          quantity: quantity,
        });
        setLoading(false);
        alertSuccessMessage("Product added successfully");
        Navigate("/cart");
      } catch (error) {
        setLoading(false);
        console.error("Error adding document: ", error);
        alertErrorMessage("Failed to add product to cart");
      }
    } else {
      setLoading(false);
      alert("You need to login first");
    }
  };

  const goToCart = () => {
    Navigate("/cart");
  };

  const otherProducts = allProductData.filter((p) => p.id !== id);

  const initialOptions = {
    clientId:
      "Af8cmRn8_x5kTvqQIbnavwDWovyyRK4GUZR_-InmjIJ1x6MzIVukmpza8BDECT7rQU5ohMTAPGeGLD_J",
    currency: "USD",
    intent: "capture",
  };

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)] backdrop-blur-sm flex justify-center z-[999] items-center">
          <div className="flex justify-center items-center">
            <Loader loading={loading} color="#FFFFFF33" />
          </div>
        </div>
      )}
      <div className="pt-[100px] bg-[#121212] flex flex-col gap-16 w-full items-center">
        <div className="w-5/6">
          <div className="w-full max-w-[1448px] mx-auto flex flex-col lg:flex-row justify-center text-[#FFFFFF] gap-3">
            <div className="flex flex-1">
              <img
                className="w-full max-w-[528px] p-[10px] border border-[#1212121A] object-cover bg-[#FFFFFF]"
                src={product?.image}
                alt="product_img"
              />
            </div>
            <div className="flex flex-1 p-2 h-fit">
              <div className="flex flex-col justify-between">
                <div>
                  <h1 className="text-2xl font-medium">{product?.name}</h1>
                </div>
                <p
                  className="text-base font-light w-full py-6 flex-grow"
                  dangerouslySetInnerHTML={{ __html: product?.about }}
                ></p>
                <div className="text-4xl font-semibold">${product?.price}</div>
                <div className="w-full max-w-[510px] pt-10">
                  <div>
                    <div className="flex flex-col sm:flex-row gap-12 pb-8">
                      <div className="w-32 h-14 flex justify-center items-center border border-gray-400 rounded-md">
                        <span
                          className="w-12 p-4 cursor-pointer"
                          onClick={handleDecrease}
                        >
                          <IoMdRemove />
                        </span>
                        <span className="w-3 text-2xl leading-[56px] font-semibold">
                          {quantity}
                        </span>
                        <span
                          className="w-12 p-4 cursor-pointer"
                          onClick={handleIncrease}
                        >
                          <IoMdAdd />
                        </span>
                      </div>

                      {/* {product?.color !== "Black" && (
                                            <div className="flex gap-[10px] items-center">
                                                <div className="h-10 w-10 bg-[#F03737]"></div>
                                                <div className="h-10 w-10 bg-[#0DECD9]"></div>
                                                <div className="h-10 w-10 bg-[#FFFFFF]"></div>
                                            </div>
                                        )} */}
                    </div>
                    <div className="flex flex-col sm:flex-row gap-3">
                      <button
                        className="w-full max-w-[249px] h-[63px] p-2 border border-[#FFFFFF99]"
                        onClick={isInCart ? goToCart : addToCart}
                      >
                        {isInCart ? "Go to Cart" : "Add to Cart"}
                      </button>
                      {/* <button className="w-full max-w-[249px] h-[63px] p-2 border border-[#FFFFFF99]" onClick={()=>{
                                            addToCart()
                                        }}>Buy Now</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            dangerouslySetInnerHTML={{ __html: product?.product_info }}
            className="text-[#FFFFFF] py-10 text-base"
          />

          <div className="w-full max-w-[1448px] mx-auto flex flex-col gap-10 text-[#FFFFFF]">
            <h1 className="text-[32px] leading-[40px] font-medium pl-6 tracking-wide">
              Other Products
            </h1>
            <div className="w-full max-w-[1448px] flex flex-wrap justify-center gap-6">
              {otherProducts.map((p) => (
                <Link
                  key={p.id}
                  to={`/productDetail/${p.id}`}
                  className="w-full max-w-[378px] flex flex-col gap-[22px]"
                >
                  <img
                    className="h-[378px] w-full "
                    src={p.image}
                    alt="product_img"
                  />
                  <div className="flex flex-col gap-4">
                    <div className="font-beautique font-medium">{p.name}</div>
                    <div className="font-medium">Price-${p.price}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;

// last se do div upar

{
  /* <div className="px-2 lg:px-6 flex flex-col gap-8 text-white">
        <h1 className="text-2xl lg:text-[32px] font-medium font-beautique tracking-wide">Ratings & Reviews</h1>
        <div className="flex flex-col lg:flex-row gap-6">
            <div className="flex flex-col items-start">
                <div className="flex gap-2 items-center">
                    <div className="text-[32px]">4.3</div>
                    <IoMdStar className="text-[28px]" />
                </div>
                <div className="text-sm text-gray-400">209 Ratings & 23 Reviews</div>
                <div className="flex flex-col gap-2 w-56">
                    {[80, 30, 10, 3, 20].map((width, i) => (
                        <div key={i} className="flex items-center gap-2">
                            <div className="flex items-center gap-1">
                                <span className="text-xs">{5 - i}</span>
                                <IoMdStar className="text-xs" />
                            </div>
                            <div className="w-full bg-gray-400 h-2 rounded overflow-hidden">
                                <div className="bg-gray-800 h-full" style={{ width: ${width}% }}></div>
                            </div>
                            <span className="text-xs text-gray-400">{[139, 37, 10, 3, 20][i]}</span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="w-full flex flex-col gap-4">
                {[...Array(2)].map((_, i) => (
                    <div key={i} className="bg-[#FFFFFF1A] p-6 rounded-lg">
                        <div className="flex justify-between items-center pb-2">
                            <span className="text-xs font-medium">Vivek Nandal</span>
                            <span className="text-xs text-gray-400">1 month ago</span>
                        </div>
                        <div className="flex items-center gap-2 pb-2">
                            <div className="flex items-center gap-1 bg-white text-black w-10 h-6 justify-center rounded-md">
                                <span>5</span>
                                <IoMdStar />
                            </div>
                            <span className="text-sm font-semibold">Great product</span>
                        </div>
                        <p className="text-sm text-gray-300">
                            Great Product. Value for money. But I didn't get my free Smart Watch till now after I pre order this mobile.
                        </p>
                    </div>
                ))}
            </div>
        </div>
    </div> */
}
