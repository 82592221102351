import { BeatLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Loader({ loading, color = "#ffffff" }) {
  return (
    <div className="sweet-loading">
      <BeatLoader
        color={color}
        loading={loading}
        cssOverride={override}
        height={9}
        width={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}

export default Loader;
