import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <div className="h-[500px] pt-[112px] px-[215px] pb-[124px] w-full text-[#FFFFFF] bg-[#121212]">
            <div className="h-[366px] flex flex-col gap-8">
                <div className="h-[298px] flex gap-6 border-b-[#FFFFFF1A] border-b-[1px]">

                    <div className="w-[344px] h-[146px] py-0 pr-12 pl-0 flex flex-col gap-[6px]">
                        <Link to="/" className="flex gap-1">
                            <img src="img/Logo.svg" className="h-12" alt="logo"/>
                            <div className="font-beautique font-bold text-5xl text-[#0BD0C0]">CELLF</div>                        
                        </Link>
                        <p>Awareness project LLC</p>
                    </div>
                    <div className="w-[345px] leading-10">
                        <ul>
                            <li>About Us</li>
                            <li>Privacy Policy</li>
                            <li>Return Policy</li>
                        </ul>
                    </div>
                    <div className="flex flex-col h-[176px] w-[344px]">
                        <div className="h-20 tracking-wide leading-7"> or send us email at <strong>info@CELLFAwareness.com</strong></div>
                        <div className="h-24 pt-6">Contact us</div>
                    </div>
                </div>
                <div className="flex h-[14px] justify-between">
                    <div>©2024 Made By a CELLF</div>
                    <div>Terms & Condition</div>
                </div>
 
            </div>

        </div> */}

      <div className="h-auto md:pt-28 pt-8 px-4 xl:px-[215px] md:pb-[124px] pb-8 w-full text-[#FFFFFF] bg-[#121212]">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col md:flex-row gap-6 border-b-[#FFFFFF1A] border-b-[1px] pb-8">
            <div className="flex flex-col gap-4 w-full md:w-[344px]">
              <Link to="/" className="flex items-center gap-2">
                <img src="img/Logo.svg" className="h-12" alt="logo" />
                <div>
                  <div className="font-beautique font-bold text-3xl md:text-4xl">
                    CELLF
                  </div>
                  <div className="text-xs">Awareness project LLC</div>
                </div>
              </Link>
              {/* <p className="text-sm md:text-base">Awareness project LLC</p> */}
            </div>
            <div className="flex flex-col w-full md:w-[345px] text-sm md:text-base">
              <ul className="space-y-2">
                <li>
                  <Link to="/aboutUs" className="hover:text-[#0BD0C0]">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/privacyPolicy" className="hover:text-[#0BD0C0]">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cancellationPolicy"
                    className="hover:text-[#0BD0C0]"
                  >
                    Cancellation Policy
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="hover:text-[#0BD0C0]">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col w-full md:w-[344px] text-sm md:text-base">
              <div className="leading-7">
                Or send us an email at <strong>info@CELLFAwareness.com</strong>
              </div>
              <div className="pt-4 pb-1">Contact us</div>
              <div className="flex gap-2 text-xl">
                <a
                  href="https://www.facebook.com/CELLFAwarenessProject"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://www.instagram.com/cellfawareness"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/in/melissa-laplante-a0a91629a/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </div>
          <div className="text-xs md:text-sm flex justify-between items-center">
            <div>©2024 Made By a CELLF</div>
            <div>
              <a href="/" className="hover:text-[#0BD0C0]">
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
