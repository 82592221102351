import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Import Firestore config
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore"; // Firestore functions

const AudioPodcast = ({ loggedUser }) => {
  const [podcasts, setPodcasts] = useState([]); // State to store the list of podcast URLs and document IDs
  const [newPodcast, setNewPodcast] = useState(""); // State to store the new podcast URL from input

  // Function to fetch podcasts from Firebase Firestore
  const fetchPodcasts = async () => {
    const querySnapshot = await getDocs(collection(db, "podcasts"));
    const podcastList = querySnapshot.docs
      .filter((doc) => doc.data().type === "audio") // Filter for audio podcasts only
      .map((doc) => ({
        id: doc.id,
        url: doc.data().url,
      }));
    setPodcasts(podcastList.reverse()); // Reverse to show the latest podcast first
  };

  // Fetch podcasts when the component mounts
  useEffect(() => {
    fetchPodcasts();
  }, []);

  // Function to handle adding a new podcast
  const addPodcast = async () => {
    if (newPodcast.trim()) {
      try {
        const docRef = await addDoc(collection(db, "podcasts"), {
          url: newPodcast,
          type: "audio", // Specify type as audio
        }); // Add new podcast to Firestore
        setPodcasts([{ id: docRef.id, url: newPodcast }, ...podcasts]); // Add new podcast with ID to state
        setNewPodcast(""); // Clear the input
      } catch (error) {
        console.error("Error adding podcast: ", error);
      }
    }
  };

  // Function to handle deleting a podcast
  const deletePodcast = async (id) => {
    try {
      await deleteDoc(doc(db, "podcasts", id)); // Delete podcast from Firestore
      setPodcasts(podcasts.filter((podcast) => podcast.id !== id)); // Remove podcast from state
    } catch (error) {
      console.error("Error deleting podcast: ", error);
    }
  };

  return (
    <div className="pt-32 bg-[#121212] text-[#FFFFFF] p-2 w-full flex justify-center">
      <div className="w-5/6 flex flex-col gap-10 items-center">
        <h1 className="text-4xl md:text-3xl sm:text-2xl font-beautique font-bold leading-loose text-center">
          Unlock Your Potential: Dive into Our Inspiring Podcasts!
        </h1>

        <div className="flex flex-col gap-4 w-full items-center">
          {/* Input field to add a new podcast */}
          {loggedUser?.email == "admin@gmail.com" && (
            <div className="flex flex-col gap-2 w-full items-center">
              <input
                type="text"
                value={newPodcast}
                onChange={(e) => setNewPodcast(e.target.value)}
                placeholder="Enter Podcast URL"
                className="w-full max-w-[400px] p-2 rounded border border-[#FFFFFF33] text-black"
              />
              <button
                onClick={addPodcast}
                className=" bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition"
              >
                Add Audio
              </button>
            </div>
          )}
          {/* Render all podcasts */}
          {podcasts.map((podcast, index) => (
            <div
              key={podcast.id}
              className="flex flex-col items-center w-full py-6"
            >
              <iframe
                className="w-full max-w-[800px] h-[400px] sm:h-[315px]"
                src={podcast.url}
                title={`Podcast ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen "
                loading="lazy"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>

              {/* Delete Button */}
              {loggedUser?.email == "admin@gmail.com" && (
                <button
                  onClick={() => deletePodcast(podcast.id)}
                  className="mt-2 bg-red-600 text-white font-bold px-4 py-2 rounded"
                >
                  Delete Podcast
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AudioPodcast;
