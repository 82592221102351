import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { alertErrorMessage } from "../components/AlertMessage";
import { collection, getDocs } from "firebase/firestore";

const Home = () => {
  const Navigate = useNavigate();

  const [animate, setAnimate] = useState(false);

  const [isVisible, setIsVisible] = useState(false);

  const [BlogData, setBlogData] = useState([]);

  const opportunitySectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  // fetch all blog data
  useEffect(() => {
    const getblogs = async () => {
      //   setLoading(true);
      try {
        const blogsArray = [];
        const path = "blogs";

        const querySnapshot = await getDocs(collection(db, path));
        querySnapshot.forEach((doc) => {
          blogsArray.push({ ...doc.data(), id: doc.id });
        });

        // Sort blogs by createdAt in descending order
        blogsArray.sort((a, b) => {
          return b.createdAt - a.createdAt; // latest first
        });

        setBlogData(blogsArray);
      } catch (error) {
        alertErrorMessage("Error fetching blogs:", error.message);
      }
      //   finally {
      //      setLoading(false);
      //   }
    };

    getblogs();
  }, []);
  //

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            if (opportunitySectionRefs[index].current) {
              opportunitySectionRefs[index].current.classList.add(
                "animate__animated",
                "animate__backInUp"
              );
              opportunitySectionRefs[index].current.style.animationDelay = `${
                index * 0.5
              }s`; // Stagger the animation by 0.5s
            }
            observer.unobserve(entry.target); // Stop observing after the animation is triggered
          }
        });
      },
      { threshold: 0.1 }
    );

    opportunitySectionRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      opportunitySectionRefs.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  //
  const blogSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            blogSectionRef.current.classList.add(
              "animate__animated",
              "animate__slideInRight"
            );
            observer.unobserve(entry.target); // Stop observing after the animation is triggered
          }
        });
      },
      { threshold: 0.1 }
    );

    if (blogSectionRef.current) {
      observer.observe(blogSectionRef.current);
    }

    return () => {
      if (blogSectionRef.current) {
        observer.unobserve(blogSectionRef.current);
      }
    };
  }, []);

  //
  const textSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            textSectionRef.current.classList.add(
              "animate__animated",
              "animate__zoomIn"
            );
            observer.unobserve(entry.target); // Stop observing after the animation is triggered
          }
        });
      },
      { threshold: 0.1 }
    );

    if (textSectionRef.current) {
      observer.observe(textSectionRef.current);
    }

    return () => {
      if (textSectionRef.current) {
        observer.unobserve(textSectionRef.current);
      }
    };
  }, []);

  // Meet the founder
  const heroSectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            heroSectionRef.current.classList.add(
              "animate__animated",
              "animate__slideInLeft"
            );
            observer.unobserve(entry.target); // Stop observing after the animation is triggered
          }
        });
      },
      { threshold: 0.1 }
    );

    if (heroSectionRef.current) {
      observer.observe(heroSectionRef.current);
    }

    return () => {
      if (heroSectionRef.current) {
        observer.unobserve(heroSectionRef.current);
      }
    };
  }, []);

  const heroSectionTextRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            heroSectionTextRef.current.classList.add(
              "animate__animated",
              "animate__slideInRight"
            );
            observer.unobserve(entry.target); // Stop observing after the animation is triggered
          }
        });
      },
      { threshold: 0.1 }
    );

    if (heroSectionTextRef.current) {
      observer.observe(heroSectionTextRef.current);
    }

    return () => {
      if (heroSectionTextRef.current) {
        observer.unobserve(heroSectionTextRef.current);
      }
    };
  }, []);

  // What we Offer
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById("offering-section");
      if (element) {
        const rect = element.getBoundingClientRect();
        const isInViewport = rect.top < window.innerHeight && rect.bottom >= 0;

        if (isInViewport) {
          setIsVisible(true);
          window.removeEventListener("scroll", handleScroll); // Optional: stop listening once the section is visible
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div className="bg-[#121212] w-full h-full">
      <div className="relative w-full pt-5 sm:pt-0">
        <div className="flex justify-center">
          <img
            src="\img\Option1HeaderHeadshot.png"
            alt="self_img"
            className=" shadow-[0_6px_7.2px_0_rgba(0,0,0,0.1)] min-h-[220px] xs:min-h-[420px] md:min-h-screen pt-0 max-h-screen"
          />
        </div>

        <div className="inset-0 absolute top-[116px] px-8 py-0 flex flex-col lg:gap-24 sm:gap-12 gap-3 w-full pt-12 sm:pt-0">
          <div className="w-full">
            <p className=" font-beautique text-[#FFFFFF] lg:text-[40px] lg:leading-[140px] md:text-3xl md:leading-[100px] sm:text-xl text-base animate__animated animate__bounce">
              FEARLESSNESS
            </p>
            <p
              className={`animate__animated ${
                animate ? "animate__flipInY" : ""
              }  font-bold font-beautique text-[#0DECD9] xl:text-[190px] xl:leading-[110px] lg:text-[150px] lg:leading-[80px] md:text-[110px] md:leading-[45px]  sm:text-[90px] sm:leading-[105px] text-[50px] leading-[60px]`}
            >
              FREQUENCY
            </p>
          </div>
          <div className="py-[10px] w-full h-[223px] sm:flex flex-col lg:gap-6 gap-3 flex-wrap animate__animated animate__backInUp">
            <p className="lg:text-[32px] lg:leading-[38px] lg:w-[538px] md:w-96 md:text-2xl sm:text-xl w-13 text-lg text-[#FFFFFF] tracking-wider">
              EMPOWERING NEURODIVERGENT PROFESSIONALS TO OVERCOME FEARS AND
              ACHIEVE DREAMS
            </p>
            <button
              className="lg:w-[260px] lg:h-[57px] w-48 h-9 lg:p-2 p-1 bg-[#FFFF] leading-5"
              onClick={() => Navigate("/masterclass")}
            >
              Explore
            </button>
          </div>
        </div>
      </div>
      <div className="h-[333px] bg-[#1A1B1F] flex items-center justify-center w-full ">
        <p
          ref={textSectionRef}
          className="md:text-[32px] sm:text-xl text-lg md:leading-[56px] text-[#FFFFFF] font-bold text-center"
        >
          Empowering Neurodivergent Professionals to escape burnout by building
          purpose-driven lives and careers or businesses.
        </p>
      </div>

      <div className="w-full py-6 px-4 flex flex-col gap-10 my-20">
        <div className="font-beautique text-3xl sm:text-4xl md:text-5xl text-[#FFFFFF] tracking-widest text-center">
          WHAT WE OFFER
        </div>

        <div id="offering-section" className="flex flex-col md:flex-row gap-6">
          <div className="flex flex-col gap-6 w-full md:w-1/2">
            <div
              className={`relative w-full ${
                isVisible ? "reveal reveal-delay-1" : ""
              }`}
            >
              <img
                className="w-full min-h-[220px] xs:min-h-[420px] md:min-h-1/2 max-h-[472px] object-cover"
                src="/img/signature_001.JPG"
                alt="service"
              />
              <div className="absolute bottom-0 left-0 right-0 px-4 sm:px-8 py-4 bg-custom-gradient">
                <p className="font-beautique text-xl sm:text-3xl md:text-5xl text-[#FFFFFF] text-center">
                  Our Passion
                </p>
                <p className="text-[#FFFFFF] text-center hidden lg:block">
                  {" "}
                  The CELLF Awareness Project, LLC is passionate about
                  empowering individuals, particularly within the Neurodivergent
                  and Neuro-Questioning communities, to embrace their authentic
                  selves and discover their unique gifts.
                </p>
                <p className="text-[#FFFFFF] text-center block lg:hidden ">
                  The CELLF Awareness Project, LLC empowers Neurodivergent
                  individuals to embrace their true selves and talents.
                </p>
              </div>
            </div>

            <div
              className={`relative w-full ${
                isVisible ? "reveal reveal-delay-2" : ""
              }`}
            >
              <img
                className="w-full min-h-[220px] xs:min-h-[420px] md:min-h-1/2 max-h-[472px] object-cover"
                src="/img/offering-img2.svg"
                alt="service"
              />
              <div className="absolute bottom-0 left-0 right-0 px-4 sm:px-8 py-4 bg-custom-gradient">
                <p className="font-beautique text-xl sm:text-3xl md:text-5xl text-[#FFFFFF] text-center">
                  Our Vision
                </p>
                <p className="text-[#FFFFFF] text-center hidden lg:block">
                  The mission of the company is to elevate humanity by helping
                  individuals lead purpose-driven lives and careers. It aims to
                  cultivate a network of professionals and entrepreneurs who can
                  collaborate on global projects that address pressing needs and
                  enhance community well-being.
                </p>
                <p className="text-[#FFFFFF] text-center block lg:hidden">
                  The company elevates humanity by guiding purposeful careers
                  and fostering global collaboration.
                </p>
              </div>
            </div>
          </div>

          <div
            className={`relative w-full md:w-1/2 ${
              isVisible ? "reveal reveal-delay-3" : ""
            }`}
          >
            <img
              className="w-full min-h-[220px] xs:min-h-[420px] md:min-h-[968px]"
              src="/img/offering-img3.svg"
              alt="service"
            />
            <div className="absolute  bottom-0 md:top-0 left-0 right-0 px-4 sm:px-8 py-4 bg-custom-gradient">
              <p className="font-beautique text-2xl sm:text-4xl md:text-6xl text-[#FFFFFF] text-center">
                Our Commitment
              </p>
              <p className="text-[#FFFFFF] text-center pt-4 hidden lg:block">
                The company is committed to nurturing every individual it
                encounters, providing the guidance and tools necessary for them
                to unlock their potential. It is dedicated to planting seeds for
                a brighter future, ensuring that everyone’s needs are met
                through shared purpose and collective action.
              </p>
              <p className="text-[#FFFFFF] text-center block lg:hidden">
                The company nurtures individuals, offering guidance and tools to
                unlock their potential and create a brighter future through
                shared purpose and collective action.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-0 pb-3 md:py-6 px-8 flex flex-col gap-8">
        {/* <!-- Title Section --> */}
        <div className="font-beautique text-3xl sm:text-4xl md:text-5xl text-[#FFFFFF] tracking-widest text-center">
          EMPOWERMENT OPPORTUNITIES
        </div>

        {/* Scrollable container */}
        <div className="md:overflow-x-auto overflow-hidden md:overflow-y-hidden w-full hide-scrollbar">
          <div className="flex flex-col md:flex-row md:flex-nowrap gap-4 justify-center md:w-max w-full">
            {/* Card 1 */}
            <div className="relative w-full md:w-[350px] inline-block group">
              <img
                ref={opportunitySectionRefs[0]}
                className="w-full min-h-[370px]"
                src="/img/book_call.jpeg"
                alt="opportunity"
              />
              <div className="absolute bottom-0 w-full bg-[#FFFFFF] flex flex-col items-center justify-center h-[0px] overflow-hidden group-hover:h-[200px] transition-all duration-500 ease-in-out gap-3 px-3">
                <h3 className="font-bold">30 minutes free</h3>
                <h2 className="opacity-0 group-hover:opacity-100 text-3xl md:text-2xl lg:text-3xl leading-7 font-beautique text-center">
                  FREE Discovery Call
                </h2>
                {/* <p className="text-center">
                  Are you ready to stop feeling stuck?
                </p> */}
                <p
                  className="underline cursor-pointer"
                  onClick={() => Navigate("/discoveryCall")}
                >
                  Read More
                </p>
              </div>
            </div>

            {/* Card 2 */}

            <div className="relative w-full md:w-[350px] inline-block group">
              <img
                ref={opportunitySectionRefs[1]}
                className="w-full min-h-[370px]"
                src="/img/feeling_stuck.jpeg"
                alt="opportunity"
              />
              <div className="absolute bottom-0 w-full bg-[#FFFFFF] flex flex-col items-center justify-center h-[0px] overflow-hidden group-hover:h-[200px] transition-all duration-500 ease-in-out gap-3 px-3">
                <h2 className="opacity-0 group-hover:opacity-100 text-3xl md:text-2xl lg:text-3xl leading-7 font-beautique text-center">
                  UNF*CK YOURSELF INTENSIVE
                </h2>
                <p className="text-center">
                  Are you ready to stop feeling stuck?
                </p>
                <p
                  className="underline cursor-pointer"
                  onClick={() => Navigate("/yourselfIntensive")}
                >
                  Read More
                </p>
              </div>
            </div>

            {/* Card 3 */}
            <div className="relative w-full md:w-[350px] inline-block group">
              <img
                ref={opportunitySectionRefs[2]}
                className="w-full min-h-[370px]"
                src="/img/frequency blueprint.webp"
                alt="opportunity"
              />
              <div className="absolute bottom-0 w-full bg-[#FFFFFF] flex flex-col items-center justify-center h-[0px] overflow-hidden group-hover:h-[200px] transition-all duration-500 ease-in-out gap-3 px-3">
                <h2 className="opacity-0 group-hover:opacity-100 text-3xl md:text-2xl lg:text-3xl leading-7 font-beautique text-center">
                  Fearlessness Frequency Blueprint
                </h2>
                <p className="text-center">
                  Unlock your true self and embrace your unique purpose with
                  confidence and clarity.
                </p>
                <p
                  className="underline cursor-pointer"
                  onClick={() => Navigate("/fearlessFrequency")}
                >
                  Read More
                </p>
              </div>
            </div>

            {/* Card 4 */}
            <div className="relative w-full md:w-[350px] inline-block group">
              <img
                ref={opportunitySectionRefs[3]}
                className="w-full min-h-[370px]"
                src="/img/purpose driven.webp"
                alt="opportunity"
              />
              <div className="absolute bottom-0 w-full bg-[#FFFFFF] flex flex-col items-center justify-center h-[0px] overflow-hidden group-hover:h-[200px] transition-all duration-500 ease-in-out gap-3 px-3">
                <h2 className="opacity-0 group-hover:opacity-100 text-3xl md:text-2xl lg:text-3xl leading-7 font-beautique text-center">
                  Purpose-Driven Business Blueprint
                </h2>
                <p className="text-center">
                  Build and launch your Purpose-Driven Business in 18 Weeks
                </p>
                <p
                  className="underline cursor-pointer"
                  onClick={() => Navigate("/businessBlueprint")}
                >
                  Read More
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-auto bg-[#0BD0C0] w-full flex flex-col items-center justify-center sm:gap-0 gap-9 py-40 px-4">
        <div className="h-[168px]  text-[#FFFFFF] ">
          <div className="h-[120px] w-full max-w-[1080px] mx-auto flex flex-col gap-[10px] items-center justify-center">
            <div className="lg:text-[32px] md:text-2xl sm:text-xl text-lg leading-[30px] tracking-widest font-semibold text-center">
              UNLOCK YOUR FEARLESSNESS FREQUENCY
            </div>
            <p className="leading-7 w-full max-w-[974px] text-center tracking-wide">
              Join our community for exclusive access to the latest blog posts,
              special deals, upcoming podcast episodes, a complimentary digital
              copy of my book, and much more! Sign up now and elevate your
              journey with us.
            </p>
          </div>
        </div>

        <div className="w-full max-w-md flex flex-col sm:flex-row gap-4 sm:items-center justify-center px-4">
          <button
            className="w-full sm:w-[115px] bg-[#121212] p-2 text-[#FFFFFF] mt-4 sm:mt-0"
            onClick={() => Navigate("/signup")}
          >
            Sign up
          </button>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-6 w-full py-8 px-4 md:px-6 bg-[#000000]">
        <img
          ref={heroSectionRef}
          src="img/signature_005.JPG"
          alt="hero"
          className="w-full md:w-[50%] object-cover h-auto"
        />
        <div
          ref={heroSectionTextRef}
          className="flex-1 flex flex-col gap-[77px] text-[#FFFFFF]"
        >
          <div className="font-beautique text-[24px] sm:text-[28px] md:text-[32px] leading-8 sm:leading-9 tracking-widest text-center md:text-left">
            READY TO FIRE FEAR AND HIRE YOURSELF?
          </div>
          <div className="flex flex-col gap-6 md:gap-8">
            <div className=" flex flex-col gap-2">
              <div className="font-beautique font-bold text-[28px] leading-7 text-center md:text-left">
                Meet the Founder
              </div>
              <div className="font-medium text-sm leading-[14px] text-center md:text-left">
                Melissa LaPlante, Motivational Speaker & Self-Empowerment Coach
              </div>
            </div>
            <p className="leading-7 text-sm md:text-base tracking-wide text-center md:text-left">
              Melissa LaPlante is a CEO, Global Keynote Speaker, Author,
              Podcaster, and Self-Empowerment Coach with over 15 years of
              experience in professional development. Specializing in
              Neurodivergent professionals, she empowers individuals to discover
              their purpose and build purpose-driven lives and businesses.
              Melissa’s mission extends to helping launch and unite
              purpose-driven businesses to address global-scale challenges.
              Through her coaching, speaking, and writing, she inspires people
              to embrace their unique talents, pursue meaningful work, and
              create lasting impact. Her passion is to help individuals thrive
              authentically, regardless of neurodiversity, while driving
              positive change for humanity.
            </p>
          </div>
        </div>
      </div>

      <div className=" overflow-x-auto overflow-y-hidden whitespace-nowrap ml-4 mt-24 scroll-container">
        <div className="inline-flex gap-4" ref={blogSectionRef}>
          {BlogData.map((item) => (
            <Link
              to={`/blogDetail/${item?.id}`}
              className="relative w-[300px] sm:w-[350px] md:w-[453px] inline-block mr-4 cursor-pointer"
            >
              <img
                src={item?.image}
                alt="blog"
                className="w-full h-[340px] object-cover"
              />
              <div className="h-[156px] bg-[#FFFFFF] absolute bottom-0 w-full py-4 px-6 flex flex-col gap-2 whitespace-pre-wrap">
                <h2 className="text-[20px] sm:text-[24px] md:text-[24px] leading-7 md:leading-9 font-beautique">
                  {item?.title}
                </h2>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
